import { createReducer, on } from '@ngrx/store';

export const initialState = null;
import { loadVehicle, loadVehicleImageSuccess, updateOdometer, updateVehicleByVin, updateVehicleByYmmt, rehydrateVehicle, updateVIN, removeVehicle } from '../_actions/vehicle.actions';


const _vehicleReducer = createReducer(initialState,
    on(loadVehicle, (state, payload) => {
        console.log('Vehicle Loaded', state, payload);
        window.sessionStorage.setItem('vehicle', JSON.stringify(payload));
        return payload;
    }),
    on(loadVehicleImageSuccess, (state, payload) => {
      console.log('Vehicle Image Loaded', state, payload);
      const vehicle =  {...state, image: payload.image};
      window.sessionStorage.setItem('vehicle', JSON.stringify(vehicle));
      return vehicle;
    }),
    on(removeVehicle, (state, payload) => {
        console.log('Vehicle Removed', state, payload);
        window.sessionStorage.removeItem('vehicle');
        return null;
    }),
    on(updateOdometer, (state, payload) => {
        console.log('Odometer Updated', payload);
        window.sessionStorage.setItem('vehicle', JSON.stringify(payload));
        return payload;
    }),
    on(updateVehicleByVin, (state, payload) => {
        console.log('Vehicle Updated By VIN', state, payload);
        window.sessionStorage.setItem('vehicle', JSON.stringify(payload));
        return payload;
    }),
    on(updateVehicleByYmmt, (state, payload) => {
        console.log('Vehicle Updated By YMMT', state, payload);
        window.sessionStorage.setItem('vehicle', JSON.stringify(payload));
        return payload;
    }),
    on(updateVIN, (state, payload) => {
        console.log('Updated Vehicle VIN', state, payload);
        sessionStorage.setItem('vehicle', JSON.stringify(payload))
        return payload;
    }),
    on(rehydrateVehicle, (state, payload) => {
        // console.log('Rehydrate Vehicle', state, payload);
        sessionStorage.setItem('vehicle', JSON.stringify(payload));
        return payload;
    })
);

export function vehicleReducer(state, action) {
    return _vehicleReducer(state, action);
}
