<div class="container account-hero-section pb-5">
    <div id="hs-custom-preferences">&nbsp;</div>
    <iframe *ngIf="(preferenceCenter$ | async) && (!urlParameters.data)" frameBorder="0" [src]="urlSafe"></iframe>

    <div class="row" *ngIf="(preferenceCenter$ | async) === false">
                
        <div class="col"></div>
        <div class="col-12 pr-3 pl-3 moveFromTop">    

        <mdb-card>
            <app-inline-loader></app-inline-loader>
            <mdb-card-body>
                <div class="text-center">
                    <h4>There is a problem with the URL supplied. Please call {{ branding.cs_phone_number }} for further assistance.</h4>
                </div>

            </mdb-card-body>
        </mdb-card>
        </div>
        <div class="col"></div>
    </div>
</div>