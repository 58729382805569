// redirect.component.ts
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import brandInfo from "../../environments/brand.json";


@Component({
    template: '' // No UI needed
})
export class RedirectComponent implements OnInit {
    constructor(private router: Router) { }

    ngOnInit(): void {
        this.router.navigate([`/${brandInfo.homePage}`], { skipLocationChange: brandInfo.hideLandingPage });
    }
}