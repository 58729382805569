import { Actions, createEffect, ofType } from '@ngrx/effects';
import { throwError } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';
import { WrapperService } from '../_services/wrapper-service.service';
import { loadRates, loadRatesHappy, ratesLoaded, ratesLoadedFailed } from '../_actions/rates.actions';
import * as ActionConfigurationManager from '../_actions/config.actions';
import { Router } from '@angular/router';
import { ratesError, success, invalidState, productExist, lamboNeverEligible, notWithinSixtyDays } from '../_actions/modal-component.actions';
import { Store } from '@ngrx/store';
import { hideInlineLoader } from '../_actions/inline-loader.actions';
import { Vehicle } from '../_models/vehicle';
import { loadVehicle, loadVehicleImage, updateOdometer } from '../_actions/vehicle.actions';
import { Injectable } from '@angular/core';
import brandInfo from "src/environments/brand.json";

@Injectable()

export class RateEffects {

    public saveRates$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadRates),
            switchMap((action: any) => this.ratesService.get_rates(action)
                .pipe(
                    map(rates => {
                        console.log('Call 1', action);

                        let _vehicle: Vehicle = JSON.parse(sessionStorage.getItem('vehicle'))

                        for (let i = 0; i < rates.data.productDetailsList.length; i++) {
                            if (rates.data.productDetailsList[i].statusCode === 100) {
                                let request: Vehicle = {
                                    isElectric: action.isElectric,
                                    make: rates.data.vehicleMake,
                                    makeExternalId: '',
                                    makeSfId: '',
                                    model: rates.data.vehicleModel,
                                    modelExternalId: '',
                                    modelSfId: '',
                                    modelNumber: '',
                                    purchaseDate: '',
                                    inserviceDate: rates.data?.inServiceDate ? rates.data.inServiceDate : null,
                                    mfrWarrantyDate: "",
                                    deliveryDate: "",
                                    exteriorColor: "",
                                    referenceNumber1: '',
                                    referenceNumber2: '',
                                    referenceNumber3: '',
                                    referenceNumber4: '',
                                    registeredState: action.customerState,
                                    mileage: action.odometer,
                                    trim: rates.data.vehicleTrim,
                                    vehicleCondition: '',
                                    vehicleExternalId: '',
                                    vehicleSfId: '',
                                    vehicleType: '',
                                    trimExternalId: '',
                                    trimSfId: '',
                                    vin: action.vin,
                                    year: rates.data.vehicleYear
                                }

                                if (_vehicle) {
                                    request.isElectric = _vehicle.isElectric
                                }

                                this.store.dispatch(loadVehicle(request))
                                this.store.dispatch(ratesLoaded(rates))
                                this.store.dispatch(loadVehicleImage(request))
                                this.store.dispatch(hideInlineLoader())
                                return success()
                            } else if (rates.data.productDetailsList[i].statusCode === 101) {
                                this.store.dispatch(ratesLoadedFailed(rates))
                                this.store.dispatch(hideInlineLoader())
                                return invalidState()
                            } else if (rates.data.productDetailsList[i].statusCode === 102) {
                                console.log('action.product', action.product)
                                console.log('rates.data.productDetailsList[i].productName', rates.data.productDetailsList[i].productName)
                                console.log('brandInfo.isMultiProduct', brandInfo.isMultiProduct)
                                if (brandInfo.isMultiProduct) {
                                    if (brandInfo.brand === 'NCESI') {
                                        if (action.product === 'PPM' && rates.data.productDetailsList[i].productName === 'NPPM') {
                                            this.store.dispatch(ratesLoadedFailed(rates))
                                            this.store.dispatch(hideInlineLoader())
                                            return productExist()
                                        } else if (action.product !== 'PPM' && rates.data.productDetailsList[i].productName === 'NASP') {
                                            this.store.dispatch(ratesLoadedFailed(rates))
                                            this.store.dispatch(hideInlineLoader())
                                            return productExist()
                                        }
                                    } else if (brandInfo.brand === 'Porsche' && rates.data.productDetailsList.length > 1) {
                                        if (i === rates.data.productDetailsList.length - 1) {
                                            this.store.dispatch(ratesLoadedFailed(rates))
                                            this.store.dispatch(hideInlineLoader())
                                            return productExist()
                                        }
                                    } else {
                                        this.store.dispatch(ratesLoadedFailed(rates))
                                        this.store.dispatch(hideInlineLoader())
                                        return productExist()
                                    }
                                    
                                } else {
                                    this.store.dispatch(ratesLoadedFailed(rates))
                                    this.store.dispatch(hideInlineLoader())
                                    return productExist()
                                }
                            } else if (rates.data.productDetailsList[i].statusCode === 103) {
                                if (brandInfo.isMultiProduct) {
                                    if (brandInfo.brand === 'NCESI') {
                                        if (action.product === 'PPM' && rates.data.productDetailsList[i].productName === 'NPPM') {
                                            this.store.dispatch(ratesLoadedFailed(rates))
                                            this.store.dispatch(hideInlineLoader())
                                            return lamboNeverEligible()
                                        } else if (action.product !== 'PPM' && rates.data.productDetailsList[i].productName === 'NASP') {
                                            this.store.dispatch(ratesLoadedFailed(rates))
                                            this.store.dispatch(hideInlineLoader())
                                            return lamboNeverEligible()
                                        }
                                    } else if (brandInfo.brand === 'Porsche' && rates.data.productDetailsList.length > 1) {
                                        if (i === rates.data.productDetailsList.length - 1) {
                                            this.store.dispatch(ratesLoadedFailed(rates))
                                            this.store.dispatch(hideInlineLoader())
                                            return lamboNeverEligible()
                                        }
                                    } else {
                                        this.store.dispatch(ratesLoadedFailed(rates))
                                        this.store.dispatch(hideInlineLoader())
                                        return lamboNeverEligible()
                                    }
                                } else {
                                    this.store.dispatch(ratesLoadedFailed(rates))
                                    this.store.dispatch(hideInlineLoader())
                                    return lamboNeverEligible()
                                }
                            } else if (rates.data.productDetailsList[i].statusCode === 104) {
                                this.store.dispatch(ratesLoadedFailed(rates))
                                this.store.dispatch(hideInlineLoader())
                                return notWithinSixtyDays()
                            } else if (rates.data.productDetailsList[i].statusCode === 105) {
                                this.store.dispatch(ratesLoadedFailed(rates))
                                this.store.dispatch(hideInlineLoader())
                                return ratesError()
                            }
                        }
                    }),
                    catchError((error) => {
                        this.store.dispatch(ratesError())
                        this.store.dispatch(hideInlineLoader())
                        return throwError(error)
                    }),
                )
            )
        )
    )

    public saveRatesHappy$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadRatesHappy),
            switchMap((action) => this.ratesService.get_rates(action)
                .pipe(
                    map(rates => {
                        console.log("************ 1");
                        console.log("1. loaded rates from api:", rates, action);
                        console.log(`2. Found ${rates.data.productDetailsList.length} products`);

                        let odometer = action.odometer && typeof action.odometer === 'string' && action.odometer.includes(',') ? action.odometer.replace(',', '') : action.odometer

                        let _vehicle: Vehicle = JSON.parse(sessionStorage.getItem('vehicle'))
                        for (let i = 0; i < rates.data.productDetailsList.length; i++) {
                            if (rates.data.productDetailsList[i].statusCode === 100) {
                                // Set the registration State and mileage.
                                if (_vehicle) {
                                    console.log('3. Update state and mileage on vehicle in store:', action)
                                    _vehicle.registeredState = action.customerState
                                    _vehicle.inserviceDate = rates.data.inServiceDate ? rates.data.inServiceDate : null
                                    _vehicle.mileage = Number(odometer)
                                    _vehicle.vehicleSfId = ''
                                    _vehicle.isElectric = rates.data.productDetailsList[i].productName.includes('EVEHICLE') ? true : false
                                    console.log('EVEHICLE:', rates.data.productDetailsList[i].productName.includes('EVEHICLE') ? true : false)
                                    _vehicle.make = rates.data.vehicleMake
                                    _vehicle.model = rates.data.vehicleModel
                                    _vehicle.trim = rates.data.vehicleTrim
                                    _vehicle.year = rates.data.vehicleYear
                                    this.store.dispatch(updateOdometer(_vehicle))
                                }
                                console.log('4. Vehicle after action', _vehicle)
                                this.store.dispatch(ratesLoaded(rates))
                                this.store.dispatch(loadVehicleImage(_vehicle));
                                console.log('5. Switch to get rates page:', _vehicle, action)
                                console.log("************");
                                // this.router.navigate([action.getRatesPage])
                                return success()
                            } else if (rates.data.productDetailsList[i].statusCode === 101) {
                                this.store.dispatch(ratesLoadedFailed(rates))
                                this.store.dispatch(hideInlineLoader())
                                return invalidState()
                            } else if (rates.data.productDetailsList[i].statusCode === 102) {
                                if (brandInfo.isMultiProduct) {
                                    if (brandInfo.brand === 'NCESI') {
                                        if (action.product === 'PPM' && rates.data.productDetailsList[i].productName === 'NPPM') {
                                            this.store.dispatch(ratesLoadedFailed(rates))
                                            this.store.dispatch(hideInlineLoader())
                                            return productExist()
                                        } else if (action.product !== 'PPM' && rates.data.productDetailsList[i].productName === 'NASP') {
                                            this.store.dispatch(ratesLoadedFailed(rates))
                                            this.store.dispatch(hideInlineLoader())
                                            return productExist()
                                        }
                                    } else if (brandInfo.brand === 'Porsche' && rates.data.productDetailsList.length > 1) {
                                        if (i === rates.data.productDetailsList.length - 1) {
                                            this.store.dispatch(ratesLoadedFailed(rates))
                                            this.store.dispatch(hideInlineLoader())
                                            return productExist()
                                        }
                                    } else {
                                        this.store.dispatch(ratesLoadedFailed(rates))
                                        this.store.dispatch(hideInlineLoader())
                                        return productExist()
                                    }
                                } else {
                                    this.store.dispatch(ratesLoadedFailed(rates))
                                    this.store.dispatch(hideInlineLoader())
                                    return productExist()
                                }
                            } else if (rates.data.productDetailsList[i].statusCode === 103) {
                                if (brandInfo.isMultiProduct) {
                                    if (brandInfo.brand === 'NCESI') {
                                        if (action.product === 'PPM' && rates.data.productDetailsList[i].productName === 'NPPM') {
                                            this.store.dispatch(ratesLoadedFailed(rates))
                                            this.store.dispatch(hideInlineLoader())
                                            return lamboNeverEligible()
                                        } else if (action.product !== 'PPM' && rates.data.productDetailsList[i].productName === 'NASP') {
                                            this.store.dispatch(ratesLoadedFailed(rates))
                                            this.store.dispatch(hideInlineLoader())
                                            return lamboNeverEligible()
                                        }
                                    } else if (brandInfo.brand === 'Porsche' && rates.data.productDetailsList.length > 1) {
                                        if (i === rates.data.productDetailsList.length - 1) {
                                            this.store.dispatch(ratesLoadedFailed(rates))
                                            this.store.dispatch(hideInlineLoader())
                                            return lamboNeverEligible()
                                        }
                                    } else {
                                        this.store.dispatch(ratesLoadedFailed(rates))
                                        this.store.dispatch(hideInlineLoader())
                                        return lamboNeverEligible()
                                    }
                                } else {
                                    this.store.dispatch(ratesLoadedFailed(rates))
                                    this.store.dispatch(hideInlineLoader())
                                    return lamboNeverEligible()
                                }
                            } else if (rates.data.productDetailsList[i].statusCode === 104) {
                                this.store.dispatch(ratesLoadedFailed(rates))
                                this.store.dispatch(hideInlineLoader())
                                return notWithinSixtyDays()
                            } else if (rates.data.productDetailsList[i].statusCode === 105) {
                                this.store.dispatch(ratesLoadedFailed(rates))
                                this.store.dispatch(hideInlineLoader())
                                return ratesError()
                            }
                        }
                        this.store.dispatch(hideInlineLoader()) // No products were returned
                        return invalidState()
                    }),
                    catchError((error) => {
                        this.store.dispatch(ratesError())
                        this.store.dispatch(hideInlineLoader())
                        return throwError(error)
                    }),
                )
            )
        )
    )

    constructor(
        private actions$: Actions,
        private ratesService: WrapperService,
        private router: Router,
        private store: Store<{}>
    ) { }
}
