import { Injectable } from "@angular/core";
import brandInfo from "src/environments/brand.json";
import { HttpClient } from "@angular/common/http";
import { HomeService } from "../_services/home.service";
import { BehaviorSubject, Observable } from "rxjs";
import { TranslateService } from "@ngx-translate/core";

interface AppSettings {
  logo: object;
  page_title: string;
  cs_phone_number: string;
  cs_email: string;
  tooltips?: Tooltip[];
  terms_and_conditions: any;
  terms_and_conditions_for_electric: any;
  languages?: Language[];
  esign_disclosure: any;
  css: string;
  scandit_key?: string;
}

interface Tooltip {
  tooltip_id: string;
  content: string;
}

interface Language {
  language_key: string;
  language_json: string;
}

@Injectable({
  providedIn: "root",
})
export class AppConfigService {
  // Fallback
  private configSettings: AppSettings = {
    logo: {
      dimensions: { width: 131, height: 23 },
      alt: null,
      copyright: null,
      url: "https://images.prismic.io/sgidigital/2cfd1eca-03a0-4222-8943-e37404158559_svgexport-1.png?auto=compress,format",
    },
    page_title: "Vroom",
    terms_and_conditions: "",
    terms_and_conditions_for_electric: "",
    esign_disclosure: "",
    cs_phone_number: "",
    cs_email: "",
    tooltips: [],
    languages: [],
    css: "",
    scandit_key: "",
  };

  public settings: BehaviorSubject<AppSettings> = new BehaviorSubject<AppSettings>(this.configSettings);

  mileage = '';

  constructor(private http: HttpClient, private contentService: HomeService, private translate: TranslateService) {
    setTimeout(() => { // Settimeout needed to allow for translate service to intialize
      translate.get("GENERIC.MILEAGE_PLURAL").subscribe(mileage => {
        console.log('mileage - appservice: ', mileage)
        this.mileage = mileage;
      })
    })
  }



  // Do this instead:
  getSettings(): Observable<AppSettings> {
    return this.settings.asObservable();
  }

  loadSettings() {
    const urlParams = new URLSearchParams(window.location.search);
    const lang = urlParams.get('lang');
    if (lang) {
      if (brandInfo.supportedLanguages.length > 0) {
        let brandLang = brandInfo.language;
        brandInfo.supportedLanguages.forEach(supportLang => {
          if (supportLang.code === lang) {
            brandLang = supportLang.code;
          }
        });
        sessionStorage.setItem('preferredLanguage', brandLang);
      } else {
        sessionStorage.setItem('preferredLanguage', brandInfo.language);
      }

    }

    const userLang = navigator.language || navigator["userLanguage"]; 

    if (brandInfo.supportedLanguages.length > 0 && !sessionStorage.getItem("preferredLanguage")) {
      let brandLang = brandInfo.language;
      brandInfo.supportedLanguages.forEach(supportLang => {
        if (supportLang.code === userLang.toLowerCase()) {
          brandLang = supportLang.code;
        }
      });
      sessionStorage.setItem('preferredLanguage', brandLang);
    }
    
    this.contentService.getBranding(brandInfo.brand, sessionStorage.getItem("preferredLanguage") ? sessionStorage.getItem("preferredLanguage") : brandInfo.language).subscribe(
      {
        next: (response) => {
          console.log("getBranding: ", response);
          if(!response.page_title) { // If the returned response doesn't contain Prismic content then try backup
            return this.loadBackupSettings()
          } else {
            this.configSettings = response;
            this.settings.next(this.configSettings);
            return true
          }
        },
        error: (err) => {
          this.settings.error(err);
          console.log("Error loading content: ", err);
          return false;
        }
      }
    );
  }

  loadBackupSettings() { // Load 'en-us' in case the registered language isn't accounted for
    this.contentService.getBranding(brandInfo.brand, 'en-us').subscribe(
      {
        next: (response) => {
          console.log("getBrandingBackup: ", response);
          this.configSettings = response;
          this.settings.next(this.configSettings);
          return true;
        },
        error: (err) => {
          this.settings.error(err);
          console.log("Error loading content: ", err);
        }
      }
    );
  }

  getLanguageJson(lang) {
    
    if (this.configSettings.languages) {
      let json = this.configSettings.languages.find((language) => language.language_key === lang.toLowerCase());
      return json ? JSON.parse(json.language_json) : null;
    }
    return null;
  }

  getCSS() {
    if (this.configSettings.css && this.configSettings.css !== '') {
      return this.configSettings.css;
    }
    return null;
  }

  getTermLengthTooltip(plan = '') {
    let tooltipText = "Term length is measured from the vehicle’s original in-service date and odometer (starting at zero " + this.mileage.toLowerCase() + "). Coverage expires when the number of months from the in-service date or number of kilometers are reached, whichever comes first.";
    const planCode = JSON.parse(sessionStorage.getItem('plan'));

    // Default to regular 'term_length' tooltip
    if (this.configSettings.tooltips){
      const defaultTooltip = this.configSettings.tooltips.find((tooltip) => tooltip.tooltip_id === "term_length");
      if (defaultTooltip) {
        tooltipText = defaultTooltip.content
      };

      // If a Plan Specific Tooltop is found then override the regular tooltop
      const planSpecificTooltip = this.configSettings.tooltips.find((tooltip) => tooltip.tooltip_id.toLowerCase() === 'term_length_' + planCode ? planCode.productCode.toLowerCase() : '')
      if (planSpecificTooltip) {
        tooltipText = planSpecificTooltip.content
      }

      if (plan !== '') {
        const planTooltip = this.configSettings.tooltips.find((tooltip) => tooltip.tooltip_id === "term_length_" + plan);
        if (planTooltip) {
          tooltipText = planTooltip.content;
        }
      }
    }
    return tooltipText;
  }

  getCommercialUseTooltip() {
    const defaultText = "Professional Services means a company or pool vehicle used for the following commercial purposes: business travel, site inspection, light delivery, service or repair, and snow removal, provided that the Covered Vehicle is equipped for snow removal, as recommended by the manufacturer. Professional Services is considered to be a Prohibited Commercial Purpose, unless the Optional Professional Services box was selected on the Registration Page. A Prohibited Commercial Purpose generally involves using the Covered Vehicle to perform commercial work-related functions, including, but not limited to: hauling, construction work, principal off-road use, pickup and/or delivery service, daily rentals, livery, carrying passenger for hire (taxi, limousine, or shuttle services), ride share vehicles, towing or road service operations, government/military use, law enforcement, fire, ambulance or other emergency services, snowplowing, company pool use, or if the Covered Vehicle is both registered/titled in a company’s name and may be used by multiple drivers.";
    if(!this.configSettings.tooltips){
      return defaultText;
    }

    const tooltip = this.configSettings.tooltips.find((tooltip) => tooltip.tooltip_id === "commercial_use");
    if (tooltip) {
      return tooltip.content;
    } else {
      return defaultText;
    }
  }

  getOdometerTooltip() {
    const tooltip = this.configSettings.tooltips.find((tooltip) => tooltip.tooltip_id === "odometer");
    if (tooltip) {
      return tooltip.content;
    }
    return null;
  }
  
  getLiftKitTooltip() {
    const defaultText = "Lift Kit tooltip text to go here";
    if(!this.configSettings.tooltips){
      return defaultText;
    }

    const tooltip = this.configSettings.tooltips.find((tooltip) => tooltip.tooltip_id === "lift_kit");
    if (tooltip) {
      return tooltip.content;
    } else {
      return defaultText;
    }
  }

  getGreyMarketTooltip() {
    const defaultText = "Grey market tooltip text to go here";
    if(!this.configSettings.tooltips){
      return defaultText;
    }

    const tooltip = this.configSettings.tooltips.find((tooltip) => tooltip.tooltip_id === "grey_market");
    if (tooltip) {
      return tooltip.content;
    } else {
      return defaultText;
    }

  }
  
  getSnowPlowTooltip() {
    const defaultText = "Snow Plow tooltip text to go here";
    if(!this.configSettings.tooltips){
      return defaultText;
    }

    const tooltip = this.configSettings.tooltips.find((tooltip) => tooltip.tooltip_id === "snow_plow");
    if (tooltip) {
      return tooltip.content;
    } else {
      return defaultText;
    }
  }

  getPaymentPlanTooltip() {
    const defaultText = "Choose your payment term length to pay off the remaining balance.";

    if(!this.configSettings.tooltips){
      return defaultText;
    }

    const tooltip = this.configSettings.tooltips.find((tooltip) => tooltip.tooltip_id === "payment_plan");
    if (tooltip) {
      return tooltip.content;
    } else {
      return defaultText;
    }
  }
  getPenniesTooltip() {

    const defaultText = "Pennies may be added to your final payment to ensure even monthly payments.";

    if(!this.configSettings.tooltips){
      return defaultText;
    }

    const tooltip = this.configSettings.tooltips.find((tooltip) => tooltip.tooltip_id === "pennies");
    if (tooltip) {
      return tooltip.content;
    } else {
      return defaultText;
    }
  }

  getEmailCheckout() {

    const defaultText = "The email you provide below will be used to access My Account. If your My Account is already set up, the email on file will be used.";

    if(!this.configSettings.tooltips){
      return defaultText;
    }

    const tooltip = this.configSettings.tooltips.find((tooltip) => tooltip.tooltip_id === "email_checkout");
    if (tooltip) {
      return tooltip.content;
    } else {
      return defaultText;
    }
  }

  getStartDateTooltip() {

    const defaultText = "Coverage Start Date is the date an active warranty on your vehicle expires.";

    if(!this.configSettings.tooltips){
      return defaultText;
    }

    const tooltip = this.configSettings.tooltips.find((tooltip) => tooltip.tooltip_id === "start_date");
    if (tooltip) {
      return tooltip.content;
    } else {
      return defaultText;
    }
  }


  getBrandInfo() {
    return this.contentService.getBranding(brandInfo.brand, sessionStorage.getItem("preferredLanguage") ? sessionStorage.getItem("preferredLanguage") : brandInfo.language);
  }
}
