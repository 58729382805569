import { Actions, createEffect, ofType, Effect } from "@ngrx/effects";
import { of, EMPTY, Observable } from "rxjs";
import { map, switchMap, catchError } from "rxjs/operators";
import { Action, Store } from "@ngrx/store";
import { Injectable } from "@angular/core";
import { VehicleService } from "../_services/vehicle.service";
import { abandonCartTriggerSuccess, getAbandonedCartFailed, getAbandonedCartSuccess, saveAbandonedCartFailed, saveAbandonedCartSuccess } from "../_actions/abandon-cart.actions";
import * as ActionConfigurationManager from '../_actions/config.actions';
import { AbandonCartService } from "../_services/abandon-cart.service";
import { updateCartSuccess } from "../_actions/cart.actions";
import { ratesLoaded } from "../_actions/rates.actions";
import { RatesResponse } from "../_models/rates";
import { savePlan } from "../_actions/plan.actions";
import { storePrismicPDF } from "../_actions/pdf.actions";
import { loadProductDisclaimers } from "../_actions/product-disclaimers.actions";
import { loadVehicle } from "../_actions/vehicle.actions";
import { account, serviceDriveAbandonedCart } from "../_actions/app-flow-state.actions";
import { validateDealerSuccess } from "../_actions/dealer-actions";
import brandInfo from "src/environments/brand.json";
import { sendDrips } from "../_actions/drips.actions";

@Injectable()
export class AbandonCartTriggerEffects {
    public abandonedCartTrigger: Observable<Action> = createEffect(() =>
        this.actions$
            .pipe(
                ofType(ActionConfigurationManager.ABANDON_CART_TRIGGER),
                switchMap((action) => this.abandonCartService.abandon_cart_trigger(action)
                    .pipe(
                        map(() => {
                            return abandonCartTriggerSuccess()
                        }),
                        catchError(() => EMPTY)
                    )
                )
            )
    )

    public getAbandonedCart: Observable<Action> = createEffect(() =>
        this.actions$
            .pipe(
                ofType(ActionConfigurationManager.GET_ABANDONED_CART),
                switchMap((action) => this.abandonCartService.get_abandoned_cart(action)
                    .pipe(
                        map((data: any) => {
                            console.log('Data from getAbandonedCart', data)

                            sessionStorage.setItem('productDisclaimers', JSON.stringify(data.data.productDisclaimers))
                            sessionStorage.setItem('vehicle', JSON.stringify(data.data.vehicle))
                            sessionStorage.setItem('rates', JSON.stringify(data.data.rates))
                            sessionStorage.setItem('prismicPDF', JSON.stringify(data.data.prismicPDF))
                            sessionStorage.setItem('plan', JSON.stringify(data.data.plan))
                            sessionStorage.setItem('cart', JSON.stringify(data.data.cart))
                            sessionStorage.setItem('dealer', JSON.stringify(data.data.dealer))
                            data.data.appFlowState === 'serviceDrive' ? this.store.dispatch(serviceDriveAbandonedCart()) : this.store.dispatch(account())
                            // sessionStorage.setItem('paymentOptionsMax', JSON.stringify(data.data.paymentOptionsMax))
                            // sessionStorage.setItem('numOfMonthlyPayments', JSON.stringify(data.data.numOfMonthlyPayments))

                            const ratesResponse: any = data.data.rates
                            const plan: any = data.data.plan
                            const cart: any = data.data.cart
                            const prismicPDF = data.data.prismicPDF
                            const productDisclaimers: any = data.data.productDisclaimers
                            const vehicle: any = data.data.vehicle
                            const dealer: any = data.data.dealer

                            this.store.dispatch(validateDealerSuccess(dealer))
                            this.store.dispatch(ratesLoaded(ratesResponse))
                            this.store.dispatch(savePlan(plan))
                            this.store.dispatch(updateCartSuccess(cart))
                            this.store.dispatch(storePrismicPDF(prismicPDF))
                            this.store.dispatch(loadProductDisclaimers(productDisclaimers))
                            this.store.dispatch(loadVehicle(vehicle))

                            return getAbandonedCartSuccess()
                        }),
                        catchError(() => {
                            this.store.dispatch(getAbandonedCartFailed())
                            return EMPTY
                        })
                    )
                )
            )
    )

    public saveAbandonedCart: Observable<Action> = createEffect(() =>
        this.actions$
            .pipe(
                ofType(ActionConfigurationManager.SAVE_ABANDONED_CART),
                switchMap((action) => this.abandonCartService.save_abandoned_cart()
                    .pipe(
                        map(() => {
                            if (brandInfo.drips && sessionStorage.getItem('drips') === 'enabled') {
                                const customer = JSON.parse(sessionStorage.getItem('customerData'))
                                const request = {
                                    email: customer.email,
                                    phone: customer.phone,
                                  }
                              
                                  this.store.dispatch(sendDrips(request))
                            }
                            return saveAbandonedCartSuccess()
                        }),
                        catchError(() => {
                            this.store.dispatch(saveAbandonedCartFailed())
                            return EMPTY
                        })
                    )
                )
            )
    )

  constructor(
    private actions$: Actions,
    private abandonCartService: AbandonCartService,
    public store: Store<{}>
  ) {}
}
