import { createReducer, on } from '@ngrx/store';
import { activateDrips, deactivateDrips, sendDrips, sendDripsFailed, sendDripsSuccess } from '../_actions/drips.actions';

export const initialState = null;


const _dripsReducer = createReducer(initialState,
    on(sendDrips, (state, payload) => {
        return null;
    }),
    on(sendDripsSuccess, (state, payload) => {
        return true;
    }),
    on(sendDripsFailed, (state, payload) => {
        return false;
    }),
    on(activateDrips, (state, payload) => {
        sessionStorage.setItem('drips', 'enabled');
        return;
    }),
    on(deactivateDrips, (state, payload) => {
        sessionStorage.setItem('drips', 'disabled');
        return null;
    })
);

export function dripsReducer(state, action) {
    return _dripsReducer(state, action);
}
