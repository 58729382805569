import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-inline-loader',
  templateUrl: './inline-loader.component.html',
  styleUrls: ['./inline-loader.component.scss']
})
export class InlineLoaderComponent implements OnInit {
  inlineLoader$: Observable<any>;
  showSpinner: boolean = false;
  showExtensionMessage: boolean = false;

  constructor(private store: Store<{
    inlineLoader: any,
  }>) { }

  ngOnInit() {

    this.inlineLoader$ = this.store.select(store => store.inlineLoader);

    this.inlineLoader$.subscribe((data) => {
      if (data) {
        this.showSpinner = true;
        setTimeout(() => {
          this.showExtensionMessage = true;
        }, 2000);
      } else {
        this.showSpinner = false;
        setTimeout(() => {
          this.showExtensionMessage = false;
        }, 2000);
      }
      console.log('INLINE LOADER DATA', data);
    })
  }
}
