import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import brandInfo from "src/environments/brand.json";
import { Location } from '@angular/common';
@Injectable({
  providedIn: "root",
})
export class PagesService {
  pages = {
    landingPage: `${brandInfo.homePage}`,
    ratingPage: "my-vsp-pricing",
    cartPage: "cart",
    checkoutPage: "checkout",
  };

  ratingPages = ["/my-vsp-pricing-electric", 
                  "/my-vsp-pricing",
                  "/electric-vsp-pricing",
                  "/vsp-pricing",
                  "/tire-wheel-pricing",
                  "/my-asp-pricing",
                  "/my-ppm-pricing",
                  "/my-pmp-pricing", 
                  "/plans-pricing",
                  "/electric-plans-pricing",
                  "/ppm-pricing",
                  "/ppm-ev-pricing",
                  "/my-lamborghini-rates"]

  constructor(private router: Router, private location: Location ) {

  }

  getRatingPages() {
    return this.ratingPages;
  }

  getLandingPage() {
    // This is a fix for an anomaly that redirects erroneously to 'help' instead of 'home' for nissan - D2C-2513
    if (this.pages.landingPage === 'help') {
      return '';
    } else {
      if (sessionStorage.getItem('appFlowState') && sessionStorage.getItem('appFlowState').includes('serviceDrive')) {
        return 'service-drive';
      } else {
        return brandInfo.homePage; 
      }
    } 
  }

  redirectTo(uri: string, queryParams = null) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = "reload";
    if (queryParams) {
      if (brandInfo.hideLandingPage && (uri.includes(brandInfo.homePage) || uri.includes(brandInfo.homePageElectric))) {
        this.router.navigate([uri], { queryParams: { oid: queryParams }, skipLocationChange: true });
        this.location.replaceState('/');
      } else {
        this.router.navigate([uri], { queryParams: { oid: queryParams }, skipLocationChange: false });
      }

    } else {
      if (brandInfo.hideLandingPage && (uri.includes(brandInfo.homePage) || uri.includes(brandInfo.homePageElectric))) {
        this.router.navigate([uri], { skipLocationChange: true })
        this.location.replaceState('/');
      } else {
        this.router.navigate([uri], { skipLocationChange: false })
      }
    }

  }

  setLandingPage(landingPage) {
    this.pages.landingPage = landingPage;
    return this.pages.landingPage;
  }

  getRatingPage() {
    return this.pages.ratingPage;
  }

  setRatingPage(ratingPage) {
    this.pages.ratingPage = ratingPage;

    window.sessionStorage.setItem("pagesFlow", JSON.stringify(this.pages));
    return this.pages.ratingPage;
  }

  isRatingPage(url) {
    return this.ratingPages.includes(url);
  }
}
