import { Action, Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, throwError } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';
import { DealerService } from '../_services/dealer.service';
import { HttpClient } from '@angular/common/http';
import * as ActionConfigurationManager from '.././_actions/config.actions';
import { Injectable } from '@angular/core';
import { ProspectService } from '../_services/prospect-service.service';
import { sendDripsFailed, sendDripsSuccess } from '../_actions/drips.actions';


@Injectable()

export class DripsEffects {

    public sendDrips$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
        ofType(ActionConfigurationManager.SEND_DRIPS),
        switchMap((action: any) => this.leadService.send_drips(action)
            .pipe(
                map((res: any) => {
                    return sendDripsSuccess()
                }),
                catchError((error) => {
                    this.store.dispatch(sendDripsFailed())
                    return throwError(error)
                })
            )
        )
    )
  )

  constructor(
    private actions$: Actions, 
    private http: HttpClient, 
    private dealerService: DealerService,
    private leadService: ProspectService,
    private store: Store<{}>
  ) {}
}





