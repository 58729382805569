import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { Subscription } from "rxjs";
import { saveCart } from "src/app/_actions/cart.actions";
import { storePrismicPDF } from "src/app/_actions/pdf.actions";
import { commercialPlan, savePlan, savePlanRatesPage } from "src/app/_actions/plan.actions";
import { loadProductDisclaimers } from "src/app/_actions/product-disclaimers.actions";
import { Opportunity } from "src/app/_models/account";
import { Cart, CartPlan, LineItems } from "src/app/_models/cart";
import { Lead } from "src/app/_models/lead";
import { PrismicPDF } from "src/app/_models/pdf";
import { Plan } from "src/app/_models/plan";
import { Product, ProductDisclaimers, ProductToolTips } from "src/app/_models/product";
import { RatesResponse } from "src/app/_models/rates";
import { Vehicle } from "src/app/_models/vehicle";
import { CartService } from "src/app/_services/cart.service";
import { PagesService } from "src/app/_services/pages.service";
import { PrismicService } from "src/app/_services/prismic.service";
import { ProductTabsetService } from "src/app/_services/product-tabset.service";
import brandInfo from "src/environments/brand.json";
import { ratesError } from "src/app/_actions/modal-component.actions";
import { ReferenceID } from "src/app/_models/reference-id";
import { TranslateService } from "@ngx-translate/core";
import { MDBModalRef, MDBModalService } from "ng-uikit-pro-standard";
import { ProductTabsetSameProductComponent } from "./product-tabset-same-product/product-tabset-same-product.component";
import { resetSingleFlowProductChanged } from "src/app/_actions/single-flow-product-changed.actions";
import { ProductTabsetPaymentTermsChangeComponent } from "./product-tabset-payment-terms-change/product-tabset-payment-terms-change.component";
import { resetMultiProductPaymentTermsChanged } from "src/app/_actions/multi-product-flow-payment-terms-changed.actions";
import { loadVehicleImage } from "src/app/_actions/vehicle.actions";
import { abandonCartTrigger } from "src/app/_actions/abandon-cart.actions";
import { AbandonCartTrigger } from "src/app/_models/abandon-cart";

@Component({
  selector: "app-slice-product-tabset",
  templateUrl: "./product-tabset.component.html",
  styleUrls: ["./product-tabset.component.scss"],
})
export class ProductTabsetComponent implements OnInit, OnDestroy {
  @Input() slice: any;
  @Input() vehicle: any;
  slices: any[];
  priceHeader: string;
  price: string;
  payment: string;
  totalPrice: string;
  coveredParts: [];
  plans: any[] = [];
  chosenPlan: any;
  commercial_use_toggle: boolean = true;
  snow_plow_toggle: boolean = true;
  lift_kit_toggle: boolean = true;
  grey_market_toggle: boolean = true;
  storePlan: Plan;
  coverageBoxes: any[];
  coverageHeader: string;
  coverageText: string;
  protectionText: string;
  prismicSub: Subscription;
  changeTerms: boolean;
  defaultPlan: string;
  deductible: any;
  mileage: any;
  modalRef: MDBModalRef
  singleFlowProductChangedSub: Subscription;
  multiProductFlowPaymentTermsChangedSub: Subscription;
  closestTerm: any
  commercialUseSet: boolean = false;
  liftKitSet: boolean = false;
  thisBrand: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private store: Store<{
      prismic: any;
      singleFlowProductChanged: any,
      multiProductFlowPaymentTermsChanged: any
    }>,
    private prismicService: PrismicService,
    private cartService: CartService,
    private productTabsetService: ProductTabsetService,
    private pageService: PagesService,
    private translate: TranslateService,
    private modalService: MDBModalService
  ) {
    this.slices = this.route.snapshot.data.prismicData;
    console.log({ productPlanSlices: this.slices });
  }

  getTabsetContent(prismicData) {
    let tabsetContent;

    // Get Tabset Content
    let tabsetContentArray = prismicData.content.filter((contentBlock) => {
      return contentBlock.type === "products_tabset";
    });

    if (tabsetContentArray.length > 0) {
      tabsetContent = tabsetContentArray[0];
    }

    return tabsetContent;
  }

  calcPaymentTerms(planTermSKUList, totalPrice, price) {
    let numberOfPayments = planTermSKUList.paymentTerms[0].split(" ")[0];
    let payment = ''

    if (brandInfo.frontLoadTaxes) {
      console.log('front load taxes is true')
      payment = Number(Number(totalPrice) / Number(numberOfPayments)).toFixed(2).toString();
    } else {
      console.log('front load taxes is false')
      payment = Number((Number(totalPrice) - Number(price)) / Number(numberOfPayments)).toFixed(2).toString();
    }
    console.log('payment', payment)
    return { numberOfPayments, payment };
  }

  mapCoverageOverview(prismicPlan) {
    let coverageOverviewArray = prismicPlan.plan.body.filter((planSection) => planSection.type === "coverage_overview");

    console.log("coverageOverviewArray: ", coverageOverviewArray);
    let coverageBoxes = [];
    let coveragetext = "";

    if (coverageOverviewArray.length > 0) {
      coverageBoxes = coverageOverviewArray[0].fields.map((coverageOverviewItem) => {
        return {
          title: coverageOverviewItem?.coverageboxtitle ? coverageOverviewItem?.coverageboxtitle[0].text : "",
          body: coverageOverviewItem?.coverageboxbody ? coverageOverviewItem?.coverageboxbody : "",
          banner: coverageOverviewItem?.coverageboxbanner ? coverageOverviewItem?.coverageboxbanner[0].text : "",
        };
      });

      if ((coveragetext = coverageOverviewArray[0].primary.coveragetext)) {
        coveragetext = coverageOverviewArray[0].primary.coveragetext[0].text;
      }
    } else {
      return {
        coverageText: "",
        coverageBoxes: [],
      };
    }

    let coverageOverview = {
      coverageText: coveragetext,
      coverageBoxes: coverageBoxes,
    };
    console.log("coverageOverview: ", coverageOverview);
    return coverageOverview;
  }

  mapComponentCoverageHeader(prismicPlan) {
    let componentCoverageArray = prismicPlan.plan.body.filter((planSection) => planSection.type === "componet_coverage");
    let coverageText = "";

    if (componentCoverageArray.length > 0) {
      console.log("coverageText: ", componentCoverageArray[0].primary.coverageheader);

      coverageText = componentCoverageArray[0].primary.coverageheader;
    }

    return coverageText;
  }

  mapCoveredComponent(prismicPlan) {
    let componentCoverageArray = prismicPlan.plan.body.filter((planSection) => planSection.type === "componet_coverage");
    let coverageText = "";

    if (componentCoverageArray.length > 0) {
      console.log("coverage_component: ", componentCoverageArray[0].primary.coverage_component);

      coverageText = componentCoverageArray[0].primary.coverage_component;
    }

    return coverageText;
  }

  mapPartsIncludeComponent(prismicPlan) {
    let componentCoverageArray = prismicPlan.plan.body.filter((planSection) => planSection.type === "componet_coverage");
    let coverageText = "";

    if (componentCoverageArray.length > 0) {
      console.log("parts_include: ", componentCoverageArray[0].primary.parts_include);

      coverageText = componentCoverageArray[0].primary.parts_include;
    }

    return coverageText;
  }

  mapPlansOverviewComponent(prismicPlan) {
    let componentPlansOverviewArray = prismicPlan.plan.body.filter((planSection) => planSection.type === "html_block");
    let html = "";

    if (componentPlansOverviewArray.length > 0) {
      for (let x = 0; x < componentPlansOverviewArray.length; x++) {
        if (componentPlansOverviewArray[x].primary.html_content_key === "plans_overview") {
          html += componentPlansOverviewArray[x].primary.html[0].text;
        }
      }
    }

    return html;
  }
  mapCardContentComponent(prismicPlan) {
    let componentBulletPointsArray = prismicPlan.plan.body.filter((planSection) => planSection.type === "html_block");
    let html = "";

    if (componentBulletPointsArray.length > 0) {
      for (let x = 0; x < componentBulletPointsArray.length; x++) {
        if (componentBulletPointsArray[x].primary.html_content_key === "card_content") {
          html += componentBulletPointsArray[x].primary.html[0].text;
        }
      }
    }

    return html;
  }

  // mapCoveredParts(prismicPlan) {
  //   let coveredParts = [];
  //   let componentCoverageArray = prismicPlan.plan.body.filter((planSection) => planSection.type === "componet_coverage");

  //   for (let l = 0; l < componentCoverageArray[0].fields.length; l++) {

  //     if(componentCoverageArray[0].fields[l].coveredpart){
  //       coveredParts.push(componentCoverageArray[0].fields[l].coveredpart);
  //     }
  //   }

  //   return coveredParts;
  // }

  mapCoveredParts(prismicPlan) {
    // console.log("prismicPlan.plan: ", prismicPlan.plan);
    let coveredParts = [];

    let notIncludedParts = prismicPlan.plan.body.find((component) => component.type == "not_included");

    // Add on coveredParts to newObject
    for (let l = 0; l < prismicPlan.plan.components.length; l++) {
      coveredParts.push({
        name: prismicPlan.plan.components[l].component_title[0].text,
        checked: true,
      });
    }

    if (notIncludedParts) {
      if (notIncludedParts.fields) {
        if (notIncludedParts.fields.length > 0) {
          for (let l = 0; l < notIncludedParts.fields.length; l++) {
            coveredParts.push({
              name: notIncludedParts.fields[l].component[0].text,
              checked: false,
            });
          }
        }
      }
    }

    return coveredParts;
  }

  // TODO: HANDLE PLAN SAMPLE POLICY
  getSamplePdf(product, plan) {

    if (plan.sample_policy) {
      // console.log("getSamplePdf plan:", plan);
      return plan.sample_policy;
    }

    if (product.sample_policy) {
      // console.log("getSamplePdf product:", product);
      return product.sample_policy;
    }
  }

  splitYears(term) {
    term = term.toUpperCase();
    return term.includes(' YEARS') ? term.split(' YEARS') : term.includes(' YEAR(S)') ? term.split(' YEAR(S)') : term.split(' YEAR');
  }

  splitMileage(term) {
    term = term.toUpperCase();
    return term.includes(' YEARS, ') ? term.split(' YEARS, ') : term.includes(' YEAR(S), ') ? term.split(' YEAR(S), ') : term.split(' YEAR, ')
  }

  ngOnInit(): void {
    sessionStorage.removeItem('numOfMonthlyPayments')
    let _rates: any = JSON.parse(sessionStorage.getItem("rates"));
    console.log(_rates);

    let _refID: ReferenceID = JSON.parse(sessionStorage.getItem("refID"));

    this.route.queryParams.subscribe((params) => {
      if (params.plan) {
        sessionStorage.setItem('presetPlan', params.plan)
        this.defaultPlan = params.plan;
      }
      else if (sessionStorage.getItem('presetPlan')) {
        this.defaultPlan = sessionStorage.getItem('presetPlan')
      }
      else if (_refID) {
        this.defaultPlan = _refID.ref_id;
      } else {
        this.defaultPlan = null;
      }
      this.thisBrand = brandInfo.brand;

    });

    this.translate.get("GENERIC.MILEAGE").subscribe(mileage => {
      console.log('mileage', mileage)
      this.mileage = mileage.toLowerCase();
    })

    this.singleFlowProductChangedSub = this.store.select((store) => store.singleFlowProductChanged).subscribe(data => {
      console.log('Single flow data', data)
      if (data) {
        this.createCart()

      }
    })

    this.multiProductFlowPaymentTermsChangedSub = this.store.select((store) => store.multiProductFlowPaymentTermsChanged).subscribe(data => {
      if (data) {
        this.createCart()
      }
    })

    this.prismicSub = this.store
      .select((store) => store.prismic)
      .subscribe((prismicData) => {
        let tabsetContent = this.productTabsetService.getTabsetContent(prismicData);
        let prismicProducts = tabsetContent.primary.product
        let prismicPlans = tabsetContent.primary.product.plans;
        this.loadDisclaimers(tabsetContent.primary.product);
        let ratingProducts = _rates.data.productDetailsList;

        console.log('Prismic Products: ', prismicProducts)
        console.log('Prismic Plans: ', prismicPlans)

        for (let k = 0; k < prismicPlans.length; k++) {
          for (let i = 0; i < ratingProducts.length; i++) {
            let ratingPlans = ratingProducts[i].productPlanList;

            for (let j = 0; j < ratingPlans.length; j++) {
              if (prismicPlans[k].plan.reference_number === ratingPlans[j].referenceNumber1) {
                let totalPrice = ratingPlans[j].planTermSKUList[0].planTermSKURate;
                let price = (Number(ratingPlans[j].planTermSKUList[0].planTermSKURate) / 10).toFixed(2).toString();
                let { numberOfPayments, payment } = this.calcPaymentTerms(ratingPlans[j].planTermSKUList[0], totalPrice, price);
                let plan = {
                  name: prismicPlans[k].plan.plan_title[0].text,
                  active: false,
                  header: "",
                  product: {
                    productName: prismicProducts.product_title[0].text,
                    ratesProductName: ratingProducts[i].productName,
                    productCode: ratingProducts[i].productCode,
                    product2ExternalId: ratingProducts[i].productExternalId,
                    product2SfId: ratingProducts[i].productSfId,
                    productFormNumber: ratingProducts[i].productFormNumber,
                    surcharge: ratingProducts[i].surcharge
                  },
                  planTerms: this.mapPlanTerms(ratingPlans[j].planTermSKUList),
                  componentCoverage: {
                    coverageHeader: this.mapComponentCoverageHeader(prismicPlans[k]),
                    coveredParts: this.mapCoveredParts(prismicPlans[k]),
                    coverageComponent: this.mapCoveredComponent(prismicPlans[k]),
                    partsInclude: this.mapPartsIncludeComponent(prismicPlans[k]),
                  },
                  plansOverview: this.mapPlansOverviewComponent(prismicPlans[k]),
                  cardContent: this.mapCardContentComponent(prismicPlans[k]),
                  coverageOverview: this.mapCoverageOverview(prismicPlans[k]),
                  samplePDF: this.getSamplePdf(tabsetContent.primary.product, prismicPlans[k].plan),
                  plan_type: prismicPlans[k].plan.plan_type,
                  plan: {
                    planName: prismicPlans[k].plan.plan_title[0].text,
                    planNumber: ratingPlans[j].planNumber,
                    planCode: ratingPlans[j].planCode,
                    referenceNumber: ratingPlans[j].referenceNumber1,
                    deductible: ratingPlans[j].deductible ? Number(ratingPlans[j].deductible) : 0,
                    commercialUse: (brandInfo.brand !== 'NCESI') && this.commercialUseSet ? true : this.doesSurchargeExist(ratingProducts[i].surcharge, ['GMC', 'Buick', 'Cadillac', 'Chevrolet'].includes(brandInfo.brand) ? 'Professional Services' : 'Light Duty'),
                    // greyMarket:  true ? true : this.doesSurchargeExist(ratingProducts[i].surcharge, 'Grey Market'),
                    // snowPlow:  true ? true : this.doesSurchargeExist(ratingProducts[i].surcharge, 'Plow'),
                    liftKit:  (brandInfo.brand === 'Chevrolet' || brandInfo.brand === 'GMC') && this.liftKitSet ? true : this.doesSurchargeExist(ratingProducts[i].surcharge, 'Lift Kit'),
                    planIndex: j,
                  },
                  term: {
                    termIndex: 0,
                    price: price,
                    payment: payment,
                    totalPrice: totalPrice,
                    priceHeader: "As low as",
                    numberOfPayments: numberOfPayments,
                    productPlanSkuId: ratingPlans[j].planTermSKUList[0].productPlanSkuId,
                    commercialUseSelected: false,
                    greyMarketSelected: false,
                    snowPlowSelected: false,
                    liftKitSelected: false,
                    disappearingDeductibleSelected: false,
                    contractDealerCost: ratingPlans[j].planTermSKUList[0].contractDealerCost,
                  },
                  previousPlan: {
                    year: this.splitYears(ratingPlans[j].planTermSKUList[0].planTermSKUName)[0],
                    mileage: 0
                  }
                };
                this.plans.push(plan);
              }
            }
          }
        }

        console.log('booleans after loop', this.commercialUseSet, this.liftKitSet)

        // console.log("this.plans: ", this.plans);

        if (this.plans.length === 0) {
          this.router.routeReuseStrategy.shouldReuseRoute = () => false;
          this.router.onSameUrlNavigation = "reload";
          let landingPageUrl = this.pageService.getLandingPage();
          this.store.dispatch(ratesError());
          // this.router.navigate([`/${landingPageUrl}`], { queryParams: { re: "true" } });
          this.router.navigate([`/${landingPageUrl}`]);

          console.error("There is no plans that matches with Prismic.", prismicPlans, ratingProducts);

        }
        this.setInitPlan();
        let _vehicle: Vehicle = JSON.parse(sessionStorage.getItem('vehicle'))
        if (_vehicle.image === undefined || _vehicle.image === '' || _vehicle.image === null) {
          this.store.dispatch(loadVehicleImage(_vehicle))
        }
      });

    let dataObject: Product = {
      productName: "",
      disclaimers: [],
      toolTips: [],
    };

    // this.doesOpportunityIdExists();
  }

  ngOnDestroy() {
    if (this.prismicSub) {
      this.prismicSub.unsubscribe();
    }
  }

  mapPlanTerms(planTerms) {
    console.log("mapPlanTerms: ", planTerms)
    let termsData = [];

    for (let j = 0; j < planTerms.length; j++) {
      termsData.push({
        value: j,
        label: planTerms[j].planTermSKUName,
        price: planTerms[j].planTermSKURate,
        contractDealerCost: planTerms[j].contractDealerCost,
        paymentTerms: planTerms[j].paymentTerms,
        planTermSKUName: planTerms[j].planTermSKUName,
        planTermSKUNumber: planTerms[j].planTermSKUNumber,
        productSkuMileage: planTerms[j].productSkuMileage,
        planTermSKURate: planTerms[j].planTermSKURate,
        productPlanSkuId: planTerms[j].productPlanSkuId,
        productSkuTerm: planTerms[j].productSkuTerm,
        productSkuId: planTerms[j].productSkuId,
        deductible: planTerms[j].deductible
      });
    }

    return termsData;

  }

  switchPlan(plan) {
    this.chosenPlan = plan;

    console.log("Plan after plan change: ", this.chosenPlan);

    this.storePlan = {
      planName: this.chosenPlan.plan.planName,
      planDetails: this.chosenPlan.plan.planDetails,
      referenceNumber: this.chosenPlan.plan.referenceNumber,
      productName: this.chosenPlan.product.productName,
      ratesProductName: this.chosenPlan.product.ratesProductName,
      productCode: this.chosenPlan.product.productCode,
      planIndex: this.chosenPlan.plan.planIndex,
      termIndex: this.chosenPlan.term.termIndex ? this.chosenPlan.term.termIndex : 0,
      yearsMileage: this.chosenPlan.planTerms[this.chosenPlan.term.termIndex].planTermSKUName,
    };

    this.store.dispatch(savePlanRatesPage(this.storePlan));
    // this.store.dispatch(commercialPlan(this.chosenPlan))

    let prismicPDF: PrismicPDF = {
      pdf: this.chosenPlan.samplePDF.url,
    };
    this.store.dispatch(storePrismicPDF(prismicPDF));

    for (let i = 0; i < this.plans.length; i++) {
      if (this.plans[i].active) {
        this.plans[i] = this.chosenPlan;
        this.deductible = this.plans[i].plan.deductible
      }
    }
    this.changeTerms = true

    console.log("Plans after plan change", this.plans);


    this.checkForTermMatch(plan)

    this.getProperPricing()

    const VISITED_PRICING_PAGE = 1;
    // this.triggerAbandonCart(VISITED_PRICING_PAGE);

  }

  triggerAbandonCart(triggerNumber){
    let _opp = JSON.parse(sessionStorage.getItem('opp'))
    if (_opp) {
      let obj: AbandonCartTrigger = {
        trigger: triggerNumber,
        opportunityID: _opp.opportunityId
      }
      this.store.dispatch(abandonCartTrigger(obj))
    }
  }

  checkForTermMatch(plan) {
    const prevYear = plan.previousPlan.year
    const prevMileage = plan.previousPlan.mileage

    let differences = []

    for (let i = 0; i < plan.planTerms.length; i++) {
      let currentYear = Number(this.splitYears(plan.planTerms[i].planTermSKUName)[0])
      let currentMileage = this.splitMileage(plan.planTerms[i].planTermSKUName)[1].split(' ')[0]

      let obj = {
        year: prevYear - currentYear,
        mileage: parseFloat(prevMileage.replace(/,/g, '')) - parseFloat(currentMileage.replace(/,/g, '')),
        index: i
      }

      differences.push(obj)
    }
    console.log('Term Differences: ', differences)

    //Checking to see if year has any perfect matches
    let subset = differences.filter(difference => difference.year === 0)
    if (subset.length > 0) {
      console.log('Year matched')
      //Checking to see if mileage has any perfect matches with perfect mactched year
      subset = subset.filter(difference => difference.mileage === 0)
      if (subset.length === 0) {
        //Reseting subset and checking if there are mileage differences with a perfect matched year
        subset = differences.filter(difference => difference.year === 0)
        //Setting initial mileage difference
        let lowestDifference = Math.abs(subset[0].mileage)
        console.log('Inital lowest difference', lowestDifference)
        let _subset = subset
        //Checking if we need to loop through mileage difference to find the lowest or just use the one set above because there is only one.
        if (subset.length > 1) {
          for (let i = 0; i < subset.length - 1; i++) {
            if (lowestDifference > Math.abs(subset[i + 1].mileage)) {
              lowestDifference = Math.abs(subset[i + 1].mileage)
              console.log('Lowest difference after', lowestDifference)
            }
          }
          subset = _subset.filter(option => option.mileage === lowestDifference)
        }
      }
    } else {
      //Checking to see if mileage has any perfect matches and we already know there are no year perfect matches
      subset = differences.filter(difference => difference.mileage === 0)
      if (subset.length > 0) {
        console.log('Mileage matched')
        //Setting initial year difference
        let lowestDifference = subset[0].year
        let _subset = subset
        //Checking if we need to loop through year difference to find the lowest or just use the one set above because there is only one
        if (subset.length > 1) {
          for (let i = 0; i < subset.length - 1; i++) {
            if (lowestDifference < subset[i + 1].year) {
              subset = _subset.filter(option => option.year === subset[i + 1].year)
            }
          }
        }
      } else {
        //We know there are no perfect matched year or perfect matched mileage
        //Checking if we need to loop through all differences
        subset = differences
        if (subset.length > 1) {
          console.log('Nothing matched')
          for (let i = 0; i < subset.length; i++) {
            //Check if previous is bigger than last one in current, if so, choose last one in current....if not, find smallest mileage difference in current where current year = previous year + 1
            //Checking if differences has a year bigger than the previos plan
            subset = subset.filter(option => option.year > plan.previousPlan.year)
            if (subset.length === 0) {
              //If differences does not have any term year bigger than the previous plan's term year, choose last option in differences
              subset = differences.slice(differences[differences.length - 1])
            } else {
              //If differences does have term years bigger than previous plan's term year, choose the first one
              subset = subset.filter(option => option === subset[0])
            }
          }
        }
      }
    }
    console.log('Subset of differences: ', subset)
    this.closestTerm = subset[0].index
  }

  initSwitchPlan(plan) {
    this.chosenPlan = plan;

    console.log("Plan after plan change: ", this.chosenPlan);

    this.storePlan = {
      planName: this.chosenPlan.plan.planName,
      planDetails: this.chosenPlan.plan.planDetails,
      referenceNumber: this.chosenPlan.plan.referenceNumber,
      productName: this.chosenPlan.product.productName,
      ratesProductName: this.chosenPlan.product.ratesProductName,
      productCode: this.chosenPlan.product.productCode,
      planIndex: this.chosenPlan.plan.planIndex,
      termIndex: this.chosenPlan.term.termIndex ? this.chosenPlan.term.termIndex : 0,
      yearsMileage: this.chosenPlan.planTerms[this.chosenPlan.term.termIndex].planTermSKUName,
    };

    this.store.dispatch(savePlanRatesPage(this.storePlan));

    let prismicPDF: PrismicPDF = {
      pdf: this.chosenPlan.samplePDF.url,
    };
    this.store.dispatch(storePrismicPDF(prismicPDF));

    for (let i = 0; i < this.plans.length; i++) {
      if (this.plans[i].active) {
        this.plans[i] = this.chosenPlan;
        this.deductible = this.plans[i].plan.deductible
      }
    }

    console.log("Plans after plan change", this.plans);

    this.setInitPreviousPlanObjects(plan)
  }
  setInitPlan() {
    // Set Chosen Plan
    if (this.plans.length > 0) {
      if (this.defaultPlan) {
        let planIndex = this.plans.findIndex((plan) => plan.plan.referenceNumber === this.defaultPlan);


        if (planIndex > 0) {
          this.plans[planIndex].active = true;
          this.initSwitchPlan(this.plans[planIndex]);
        } else {
          this.plans[0].active = true;
          this.initSwitchPlan(this.plans[0]);
        }
      } else {
        this.plans[0].active = true;
        this.initSwitchPlan(this.plans[0]);
      }
    }


  }

  doesSurchargeExist(surchargeOptions, type) {
    if(surchargeOptions){
      for (let i = 0; i < surchargeOptions.surchargeOption.length; i++) {
        console.log('doesSurchargeExist surchargeOptions', surchargeOptions)
        console.log('doesSurchargeExist type', type)
        console.log(surchargeOptions.surchargeOption[i].label.enLabel.toLowerCase().includes(type.toLowerCase()))
        if (surchargeOptions.surchargeOption[i].label.enLabel.toLowerCase().includes(type.toLowerCase())) {
          if (type === 'Light Duty') {
            this.commercialUseSet = true
          }

          if (type === 'Professional Services') {
            this.commercialUseSet = true
          }

          if (type === 'Lift Kit') {
            this.liftKitSet = true
          }
          console.log('Booleans after loop 2', this.commercialUseSet, this.liftKitSet)
          return true
        }
      }
    }
  }

  setInitPreviousPlanObjects(plan) {
    for (let i = 0; i < this.plans.length; i++) { //this.splitYears(plan.planTerms[i].planTermSKUName)[0]
      this.plans[i].previousPlan.year = Number(this.splitYears(plan.planTerms[0].planTermSKUName)[0])
      this.plans[i].previousPlan.mileage = this.splitMileage(plan.planTerms[0].planTermSKUName)[1].split(' ')[0]
    }
    console.log('Plans after previous plans set: ', this.plans)
  }

  setPreviousPlanObjects(plan) {
    for (let i = 0; i < this.plans.length; i++) {
      this.plans[i].previousPlan.year = Number(this.splitYears(plan.planTerms[plan.term.termIndex].planTermSKUName)[0])
      this.plans[i].previousPlan.mileage = this.splitMileage(plan.planTerms[plan.term.termIndex].planTermSKUName)[1].split(' ')[0]
    }
    console.log('Plans after previous plans set: ', this.plans)
  }



  getProperPricing() {
    if (brandInfo.multiDeductible) {
      let _rates: any = JSON.parse(sessionStorage.getItem("rates"));
      const info = this.chosenPlan.planTerms[this.chosenPlan.term.termIndex].deductible.filter(item => {
        return item.amount === this.chosenPlan.plan.deductible && item.disappearing === this.chosenPlan.term.disappearingDeductibleSelected
      })

      if (info.length > 0) {
        this.chosenPlan.term.disappearingDeductibleSelected = info[0].disappearing
        this.chosenPlan.term.contractDealerCost = info[0].price
        this.chosenPlan.term.productPlanSkuId = info[0].sku


        let surcharges = 0
        if (this.chosenPlan.term.commercialUseSelected) {
          surcharges += this.productTabsetService.getPriceForSurcharge('Light Duty')
          this.commercial_use_toggle = true
        } else {
          this.commercial_use_toggle = false
        }

        if (this.chosenPlan.term.commercialUseSelected) {
          surcharges += this.productTabsetService.getPriceForSurcharge('Professional Services')
          this.commercial_use_toggle = true
        } else {
          this.commercial_use_toggle = false
        }

        if (this.chosenPlan.term.liftKitSelected) {
          surcharges += this.productTabsetService.getPriceForSurcharge('Lift Kit')
          this.lift_kit_toggle = true
        }  else {
          this.lift_kit_toggle = false
        }

        if (this.chosenPlan.term.greyMarketSelected) {
          surcharges += this.productTabsetService.getPriceForSurcharge('Grey Market')
          this.grey_market_toggle = true
        }  else {
          this.grey_market_toggle = false
        }

        if (this.chosenPlan.term.snowPlowSelected) {
          surcharges += this.productTabsetService.getPriceForSurcharge('Plow')
          this.snow_plow_toggle = true
        }  else {
          this.snow_plow_toggle = false
        }

        if (!this.chosenPlan.term.commercialUseSelected && !this.chosenPlan.term.liftKitSelected && !this.chosenPlan.term.greyMarketSelected && !this.chosenPlan.term.snowPlowSelected) {
          this.chosenPlan.term.totalPrice = info[0].price
        } else {
          this.chosenPlan.term.totalPrice = Number(info[0].price) + surcharges
        }

        this.chosenPlan.term.price = brandInfo.brand === 'Hyundai' ? (Number(this.chosenPlan.term.totalPrice) * .20).toFixed(2).toString() : (Number(this.chosenPlan.term.totalPrice) * .10).toFixed(2).toString()

        if (brandInfo.frontLoadTaxes) {
          this.chosenPlan.term.payment = ((Number(this.chosenPlan.term.totalPrice)) / Number(this.chosenPlan.term.numberOfPayments)).toFixed(2).toString()
        } else {
          this.chosenPlan.term.payment = ((Number(this.chosenPlan.term.totalPrice - Number(this.chosenPlan.term.price))) / Number(this.chosenPlan.term.numberOfPayments)).toFixed(2).toString()
        }

      }
    } else {
      const info = this.chosenPlan.planTerms[this.chosenPlan.term.termIndex]

      this.chosenPlan.term.contractDealerCost = info.price
      this.chosenPlan.term.productPlanSkuId = info.productPlanSkuId

      let surcharges = 0
        if (this.chosenPlan.term.commercialUseSelected) {
          surcharges += this.productTabsetService.getPriceForSurcharge('Light Duty')
          this.commercial_use_toggle = true
        } else {
          this.commercial_use_toggle = false
        }

        if (this.chosenPlan.term.commercialUseSelected) {
          surcharges += this.productTabsetService.getPriceForSurcharge('Professional Services')
          this.commercial_use_toggle = true
        } else {
          this.commercial_use_toggle = false
        }

        if (this.chosenPlan.term.liftKitSelected) {
          surcharges += this.productTabsetService.getPriceForSurcharge('Lift Kit')
          this.lift_kit_toggle = true
        }  else {
          this.lift_kit_toggle = false
        }

        if (this.chosenPlan.term.greyMarketSelected) {
          surcharges += this.productTabsetService.getPriceForSurcharge('Grey Market')
          this.grey_market_toggle = true
        }  else {
          this.grey_market_toggle = false
        }

        if (this.chosenPlan.term.snowPlowSelected) {
          surcharges += this.productTabsetService.getPriceForSurcharge('Plow')
          this.snow_plow_toggle = true
        }  else {
          this.snow_plow_toggle = false
        }

        if (!this.chosenPlan.term.commercialUseSelected && !this.chosenPlan.term.liftKitSelected && !this.chosenPlan.term.greyMarketSelected && !this.chosenPlan.term.snowPlowSelected) {
          this.chosenPlan.term.totalPrice = info.price
        } else {
          this.chosenPlan.term.totalPrice = Number(info.price) + surcharges
        }

        this.chosenPlan.term.price = brandInfo.brand === 'Hyundai' ? (Number(this.chosenPlan.term.totalPrice) * .20).toFixed(2).toString() : (Number(this.chosenPlan.term.totalPrice) * .10).toFixed(2).toString()

        if (brandInfo.frontLoadTaxes) {
          this.chosenPlan.term.payment = ((Number(this.chosenPlan.term.totalPrice)) / Number(this.chosenPlan.term.numberOfPayments)).toFixed(2).toString()
        } else {
          this.chosenPlan.term.payment = ((Number(this.chosenPlan.term.totalPrice - Number(this.chosenPlan.term.price))) / Number(this.chosenPlan.term.numberOfPayments)).toFixed(2).toString()
        }
    }
  }

  changeTerm(plan) {
    let _rates: any = JSON.parse(sessionStorage.getItem("rates"));
    this.chosenPlan = plan;
    this.closestTerm = this.chosenPlan.term.termIndex

    console.log("Plan after term change 2: ", this.chosenPlan);



    this.storePlan = {
      planName: this.chosenPlan.plan.planName,
      planDetails: this.chosenPlan.plan.planDetails,
      referenceNumber: this.chosenPlan.plan.referenceNumber,
      productName: this.chosenPlan.product.productName,
      ratesProductName: this.chosenPlan.product.ratesProductName,
      productCode: this.chosenPlan.product.productCode,
      planIndex: this.chosenPlan.plan.planIndex,
      termIndex: this.chosenPlan.term.termIndex ? this.chosenPlan.term.termIndex : 0,
      yearsMileage: this.chosenPlan.planTerms[this.chosenPlan.term.termIndex].planTermSKUName,
    };


    this.store.dispatch(savePlanRatesPage(this.storePlan));



    for (let i = 0; i < this.plans.length; i++) {
      if (this.plans[i].active) {
        this.plans[i] = this.chosenPlan;
        this.deductible = this.plans[i].plan.deductible
      }
    }

    this.getProperPricing()

    console.log("Plans after term change", this.plans);
    this.setPreviousPlanObjects(plan)

    const VISITED_PRICING_PAGE = 1;
    // this.triggerAbandonCart(VISITED_PRICING_PAGE);
  }

  doesOpportunityIdExists() {
    let _opp: Opportunity = JSON.parse(sessionStorage.getItem("opp"));

    if (!_opp) {
      console.error("The user went through True Guest Flow Architecture!");
      this.store.dispatch(ratesError());
      let landingPageUrl = this.pageService.getLandingPage();
      this.pageService.redirectTo(`/${landingPageUrl}`, null);
      return false;
    }
    return true;
  }

  formatTerms(term) {
    let year = term.split(' ')[0]
    let mileage = term.split(', ')[1].split(' ')[0]
    return this.translate.instant("CART_CHECKOUT.YEARS_MILES_TERM", { years:year, miles:mileage, mileage: this.mileage })
  }

  createCart() {
    if (brandInfo.brand !== 'NCESI') {
      sessionStorage.removeItem('cart')
    }

    let _lead: Lead = JSON.parse(sessionStorage.getItem("lead"));
    let _vehicle: Vehicle = JSON.parse(sessionStorage.getItem("vehicle"));
    let _opp: Opportunity = JSON.parse(sessionStorage.getItem("opp"));
    let _rates: RatesResponse = JSON.parse(sessionStorage.getItem("rates"));
    let _cart: Cart = JSON.parse(sessionStorage.getItem('cart'))

    // if(!this.doesOpportunityIdExists()){
    //   return;
    // }

    this.storePlan = {
      planName: this.chosenPlan.plan.planName,
      planDetails: this.chosenPlan.plan.planDetails,
      referenceNumber: this.chosenPlan.plan.referenceNumber,
      productName: this.chosenPlan.product.productName,
      ratesProductName: this.chosenPlan.product.ratesProductName,
      productCode: this.chosenPlan.product.productCode,
      planIndex: this.chosenPlan.plan.planIndex,
      termIndex: this.chosenPlan.term.termIndex ? this.chosenPlan.term.termIndex : 0,
      yearsMileage: this.chosenPlan.planTerms[this.chosenPlan.term.termIndex].planTermSKUName,
    };

    this.store.dispatch(savePlan(this.storePlan));

    console.log('Create cart called', this.chosenPlan)

    let cart: Cart = {
      shoppingCartId: _opp ? _opp.opportunityId : null,
      commercialUse: false,
      liftKit: false,
      snowPlow: false,
      greyMarket: false,
      deductible: brandInfo.multiDeductible ? this.chosenPlan.term.deductible : this.chosenPlan.plan.deductible,
      isDisappearing: false,
      subtotal: 0,
      estimatedTax: 0,
      grandTotal: 0,
      numberOfMonthlyPayments: 0,
      monthlyPayment: 0,
      downPayment: 0,
      downPaymentWithTax: 0,
      plans: [],
      shoppingCartData: {
        accountExternalId: "",
        accountSfId: _lead ? _lead.customer.accountSfId : null,
        brandExternalId: "",
        brandSfId: _lead ? _lead.brandSfId : null,
        contactExternalId: "",
        contactSfId: _lead ? _lead.customer.contactSfId : null,
        lineItems: [],
        shoppingCartReferenceNumber1: "",
        shoppingCartReferenceNumber2: "",
        shoppingCartReferenceNumber3: "",
        shoppingCartReferenceNumber4: "",
        status: "",
        vehicleExternalId: "",
        vehicleSfId: _vehicle ? _vehicle.vehicleSfId : null,
      },
    };

    if (_cart) {
      cart = this.checkIfProductExist()
    }

    for (let i = 0; i < this.plans.length; i++) {
      console.log('ProductPlanSkuId', this.plans[i].term.productPlanSkuId)
      if (this.plans[i].active) {
        cart.commercialUse = this.plans[i].term.commercialUseSelected
        cart.liftKit = this.plans[i].term.liftKitSelected
        cart.greyMarket = this.plans[i].term.greyMarketSelected
        cart.snowPlow = this.plans[i].term.snowPlowSelected
        cart.subtotal = Number(cart.subtotal) + Number(this.plans[i].term.totalPrice);
        cart.isDisappearing = this.plans[i].term.disappearingDeductibleSelected
        cart.deductible = brandInfo.multiDeductible ? this.plans[i].term.deductible : this.plans[i].plan.deductible
      
        if (brandInfo.brand === 'NCESI') {
          if (this.plans[i].product.productCode === 'NASP') {
            cart.numberOfMonthlyPayments = this.plans[i].term.numberOfPayments
          } else {
            if (cart.plans.length < 1) {
              cart.numberOfMonthlyPayments = this.plans[i].term.numberOfPayments
            }
          }
        } else {
          console.log('Took else', cart.numberOfMonthlyPayments)
          if ((cart.numberOfMonthlyPayments > Number(this.plans[i].term.numberOfPayments) || cart.numberOfMonthlyPayments === 0)) {
            console.log('Check out here Ray')
            cart.numberOfMonthlyPayments = Number(this.plans[i].term.numberOfPayments);
          }
        }

        const downPaymentPercentage = brandInfo.brand === 'Hyundai' ? .20 : .10
        cart.downPayment = Number((cart.subtotal * downPaymentPercentage).toFixed(2).toString())
        cart.monthlyPayment = Number((Number(Number(cart.subtotal) - Number(cart.downPayment)) / Number(cart.numberOfMonthlyPayments)).toFixed(2).toString())

        let cartPlan: CartPlan = {
          referenceNumber: this.plans[i].plan.referenceNumber,
          productCode: this.plans[i].product.productCode,
          productName: this.plans[i].product.productName,
          ratesProductName: this.plans[i].product.ratesProductName,
          planDetails: this.chosenPlan.plan.planDetails,
          planName: this.plans[i].plan.planName,
          planIndex: this.plans[i].plan.planIndex,
          termIndex: this.plans[i].term.termIndex,
          planPrice: this.plans[i].term.totalPrice,
          planTerms: {
            yearsMileage: this.formatTerms(this.plans[i].planTerms[this.plans[i].term.termIndex].planTermSKUName),
            deductible: Number(this.plans[i].plan.deductible)
          },
          skuId: this.plans[i].term.productPlanSkuId,
          productSkuId: this.plans[i].product.product2SfId,
          addons: [],
          prismicPDF: this.plans[i].samplePDF.url,
          planCode: this.plans[i].plan.planCode,
          contractDealerCost: this.plans[i].term.contractDealerCost,
          productFormNumber: this.plans[i].product.productFormNumber,
          surcharge: this.plans[i].product.surcharge
        };

        let lineItem : LineItems = {
          currency: brandInfo.isoCode,
          itemDescription: this.plans[i].product.productName,
          itemInCartFrom: this.getFormattedDate(),
          itemName: this.plans[i].plan.planName,
          lineItemCostExcludingTax: this.plans[i].term.totalPrice,
          lineItemCostIncludingTax: 0,
          shoppingCartLineItemId: 0,
          shoppingCartLineItemReferenceNumber1: "",
          shoppingCartLineItemReferenceNumber2: "",
          shoppingCartLineItemReferenceNumber3: "",
          shoppingCartLineItemReferenceNumber4: "",
          status: "",
          salesTax: 0,
          product2ExternalId: this.plans[i].product.product2ExternalId,
          product2Sfid: this.plans[i].product.product2SfId,
          productSkuExternalId: "",
          productSkuSfId: this.plans[i].term.productSkuSfId,
          productName: this.plans[i].product.productName,
          yearTerm: this.plans[i].planTerms[this.plans[i].term.termIndex].planTermSKUName.split(' ')[0],
          mileageTerm: this.plans[i].planTerms[this.plans[i].term.termIndex].planTermSKUName.split(', ')[1].split(' ')[0],
          deductible: brandInfo.multiDeductible ? this.plans[i].term.deductible : this.plans[i].plan.deductible,
          lightDuty: cart.commercialUse,
          liftKit: cart.liftKit

        };

        cart.shoppingCartData.lineItems.push(lineItem);
        cart.plans.push(cartPlan);
      }
    }

    console.log('Cart After Item is Added:', cart)

    this.store.dispatch(saveCart(cart));
    this.store.dispatch(resetSingleFlowProductChanged())
    this.store.dispatch(resetMultiProductPaymentTermsChanged())
    this.router.navigate(["/cart"]);
  }

  addToCart() {

    const VISITED_ADD_CART = 2;
    // this.triggerAbandonCart(VISITED_ADD_CART);

    console.log("addToCart: ", this.chosenPlan);
    let storedLength = 0
    let newLength = 0

    let _cart: Cart = JSON.parse(sessionStorage.getItem('cart'))
    if (_cart && _cart.shoppingCartData.lineItems.length > 0 && _cart.shoppingCartData.lineItems[0].itemDescription !== this.chosenPlan.product.productName && !brandInfo.isMultiProduct) {
      this.modalRef = this.modalService.show(ProductTabsetSameProductComponent)
    } else {
      let _rates: RatesResponse = JSON.parse(sessionStorage.getItem('rates'))
      if (_cart && _cart.shoppingCartData.lineItems.length > 0 && _cart.plans[0].productCode !== this.chosenPlan.product.productCode) {
        for (let i = 0; i < _rates.data.productDetailsList.length; i++) {
          if (_rates.data.productDetailsList[i].productCode === _cart.plans[0].productCode) {
            storedLength = _rates.data.productDetailsList[i].productPlanList[_cart.plans[0].planIndex].planTermSKUList[_cart.plans[0].termIndex].paymentTerms.length
          }
        }
        for (let i = 0; i < _rates.data.productDetailsList.length; i++) {
          if (_rates.data.productDetailsList[i].productCode === this.chosenPlan.product.productCode) {
            newLength = _rates.data.productDetailsList[i].productPlanList[this.chosenPlan.plan.planIndex].planTermSKUList[this.chosenPlan.term.termIndex].paymentTerms.length
          }
        }
        if (brandInfo.brand === 'NCESI') {
          if ((newLength !== storedLength && (this.chosenPlan.product.productCode === 'NASP' || this.chosenPlan.product.productCode === 'NPPM'))) {
            this.modalRef = this.modalService.show(ProductTabsetPaymentTermsChangeComponent)
          } else {
            this.createCart()
          }
        } else {
          if (newLength > storedLength) {
            this.modalRef = this.modalService.show(ProductTabsetPaymentTermsChangeComponent)
          } else {
            this.createCart()
          }
        }
      } else {
        this.createCart()
      }
    }
  }

  checkIfProductExist() {
    let _cart: Cart = JSON.parse(sessionStorage.getItem('cart'))

    for (let i = 0; i < this.plans.length; i++) {
      if (this.plans[i].active) {
        for (let j = 0; j < _cart.plans.length; j++) {

          if (_cart.plans[j].productName === this.plans[i].product.productName) {

            _cart.plans = _cart.plans.filter(plan => {
              if (plan.productName !== this.plans[i].product.productName) {
                return plan
              }
            })

          }
        }

        for (let k = 0; k < _cart.shoppingCartData.lineItems.length; k++) {
          if (_cart.shoppingCartData.lineItems[k].itemDescription === this.plans[i].product.productName) {
            _cart.commercialUse = this.plans[i].term.commercialUseSelected
            _cart.subtotal = _cart.subtotal - Number(_cart.shoppingCartData.lineItems[k].lineItemCostExcludingTax);


            _cart.shoppingCartData.lineItems = _cart.shoppingCartData.lineItems.filter(lineItem => {
              if (lineItem.itemDescription !== this.plans[i].product.productName) {
                return lineItem
              }
            })
          }
        }
      }
    }
    console.log('Cart after the check', _cart)
    return _cart
  }

  getTerms(boolean) {
    this.changeTerms = boolean;
    console.log("changeTerms", this.changeTerms);
  }

  getHtml(content, vehicleObject) {
    return this.prismicService.getHtml(content, vehicleObject);
  }

  loadDisclaimers(product) {
    let dataObject: Product = {
      productName: "",
      disclaimers: [],
      toolTips: [],
    };

    //Grab product title
    dataObject.productName = product.product_title[0].text;

    // Loop through disclaimers
    for (let k = 0; k < product.state_disclaimers.length; k++) {
      let disclaimers: ProductDisclaimers = {
        type: product.state_disclaimers[k].description[0].type,
        text: product.state_disclaimers[k].description[0].text,
        state: product.state_disclaimers[k].state,
        spans: product.state_disclaimers[k].description[0].spans,
      };
      dataObject.disclaimers.push(disclaimers);
    }
    //Loop through toolTips
    if (product.tooltips) {
      for (let k = 0; k < product.tooltips.length; k++) {
        if (product.tooltips[k].description) {
          let toolTip: ProductToolTips = {
            type: product.tooltips[k].description[0].type,
            text: product.tooltips[k].description[0].text,
            tooltip_id: product.tooltips[k].tooltip_id,
            spans: product.tooltips[k].description[0].spans,
          };
          dataObject.toolTips.push(toolTip);
        }
      }
    }

    //Send to store and return
    console.log("loadDisclaimers: ", dataObject);
    this.store.dispatch(loadProductDisclaimers(dataObject));
  }

  getFormattedDate() {
    let d = new Date();
    let year = d.getFullYear().toString();
    let day = d.getDate().toString();
    let month = d.getMonth().toString();

    if (month === "0") {
      month = "01";
    } else if (month === "1") {
      month = "02";
    } else if (month === "2") {
      month = "03";
    } else if (month === "3") {
      month = "04";
    } else if (month === "4") {
      month = "05";
    } else if (month === "5") {
      month = "06";
    } else if (month === "6") {
      month = "07";
    } else if (month === "7") {
      month = "08";
    } else if (month === "8") {
      month = "09";
    }

    if (day === "1") {
      day = "01";
    } else if (day === "2") {
      day = "02";
    } else if (day === "3") {
      day = "03";
    } else if (day === "4") {
      day = "04";
    } else if (day === "5") {
      day = "05";
    } else if (day === "6") {
      day = "06";
    } else if (day === "7") {
      day = "07";
    } else if (day === "8") {
      day = "08";
    } else if (day === "9") {
      day = "09";
    }
    let x = d.toISOString().split("T");
    console.log(x[0] + " " + x[1]);
    console.log(x[1].substr(0, 8));
    return x[0] + " " + x[1].substr(0, 8);
  }
}
