import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { AppConfigService } from 'src/app/appconfig/appconfig.service';
import { Cart } from 'src/app/_models/cart';
import { Plan } from 'src/app/_models/plan';
import { PagesService } from 'src/app/_services/pages.service';
import brandInfo from "src/environments/brand.json";
import { clear } from 'src/app/_actions/modal-component.actions'
import { Vehicle } from 'src/app/_models/vehicle';
import { OktaSignOutRequest, OktaUser } from 'src/app/_models/okta';
import { oktaSignOut, resetOktaUser } from 'src/app/_actions/okta.actions';
import { Lead } from 'src/app/_models/lead';
import { TranslateService } from '@ngx-translate/core';
import { resetDealer, validateDealerSuccess } from 'src/app/_actions/dealer-actions';
import { clearCart } from 'src/app/_actions/cart.actions';
import { AccountResponse } from 'src/app/_models/account';
import { RateData } from 'src/app/_models/rates';
import { resetRates } from 'src/app/_actions/rates.actions';
import { removeVehicle } from 'src/app/_actions/vehicle.actions';

@Component({
  selector: "app-desktop-nav",
  templateUrl: "./desktop-nav.component.html",
  styleUrls: ["./desktop-nav.component.scss"],
})
export class DesktopNavComponent implements OnInit {
  @Input() logo: any;
  @Input() navLinks: any;
  @Input() isLogin: boolean;
  public cartCount = 0;
  cartSubscription: Subscription;
  @Output("callbackSignOut") callbackSignOut: EventEmitter<any> = new EventEmitter();
  @Output("showCart") showCart: EventEmitter<any> = new EventEmitter();
  public cart$: Observable<Cart>;
  public lead$: Observable<Lead>;
  branding: any;
  brandInfo: any;
  isCheckoutPage: boolean;
  useNissanMenu = false;
  usePorcheMenu = false;
  isCartPage = false;
  isHelpPage = false;
  currentProductLink = "";
  dealerName = null;
  oktaUserSub: Subscription;
  oktaId: any;
  login: any;
  access_token: any;
  product1: string;
  product2: string;
  products: string;
  currentURL: string;
  compareProduct: string;
  dealer: any;
  dealerSub: Subscription;
  showSDLogout = false;
  dealerAgent = "";
  showPhoneNumber: boolean;
  showMobile: boolean;
  customer = '';
  showPorscheMenuICE = false; 
  showPorscheMenuEV = false; 
  hasVehicle = false;
  accountSub: Subscription;
  constructor(
    private translate: TranslateService,
    private router: Router,
    private appConfig: AppConfigService,
    private el: ElementRef,
    private store: Store<{
      cart: Cart;
      plan: Plan;
      oktaUser: any
      lead: Lead;
      dealer: any;
      vehicle: Vehicle;
      account: any;
    }>,
    private pageService: PagesService
  ) {
    console.log("using language 2: ", this.translate.currentLang);
  }

  ngOnInit(): void {
    this.brandInfo = brandInfo;

    if (brandInfo.brand === "NCESI") {
      // Nissan Canada has products dropdown menu
      this.useNissanMenu = true;
    } else if (brandInfo.brand === "Porsche") {
      this.usePorcheMenu = true;
    }

    if (brandInfo.showDealerName) {
      this.lead$ = this.store.select((store) => store.lead);
      this.lead$.subscribe((lead) => {
        if (lead && lead.dealerName && sessionStorage.getItem('appFlowState') !== 'serviceDrive') {
          this.dealerName = lead.dealerName;
        }
      })
    }
    this.cart$ = this.store.select((store) => store.cart);
    this.cartSubscription = this.cart$.subscribe((cart) => {
      if (cart && cart.shoppingCartData.lineItems) {
        this.cartCount = cart.shoppingCartData.lineItems.length;
      } else {
        this.cartCount = 0;
      }
    });

    this.store.select((store) => store.vehicle).subscribe((vehicle) => {
      if (vehicle && vehicle.registeredState) {  
        this.hasVehicle = true;
      } else {
        this.hasVehicle = false;
      }
    });

    this.appConfig.getSettings().subscribe((result) => {
      this.branding = result;
    });

    this.accountSub = this.store.select(store => store.account).subscribe(data => {
      if (data && data.data) {
        this.customer = data.data[0].customer.firstName + " " + data.data[0].customer.lastName.split('')[0] + ".";
      }
    })


    this.oktaUserSub = this.store.select(store => store.oktaUser).subscribe(data => {
      if (data !== null && data !== false) {
        this.oktaId = data.id
        this.login = data.login
        this.access_token = data.access_token
      } else if (data === false) {
        this.callbackSignOut.emit();
        this.store.dispatch(resetOktaUser())
      }
    })

    this.dealerSub = this.store.select(store => store.dealer).subscribe(data => {
      setTimeout(() => {
        if (data && sessionStorage.getItem('appFlowState') && sessionStorage.getItem('appFlowState').includes('serviceDrive')) {
          if (data && data.data) {
            this.dealerName = data.data.name;
            if (brandInfo.brand === "Schomp") {
              this.dealerAgent = "Logout " + data.data.employeeId + "";
              this.showSDLogout = true;
            } else if (brandInfo.brand === "VCIVW") {
              if (data.data.employeeLastName === '') {
                this.showSDLogout = false;
              } else {
                let name = " " + data.data.employeeLastName ? data.data.employeeFirstName + " " + data.data.employeeLastName.split('')[0] + "." : '';
                this.dealerAgent = "Logout " + name + "";
                this.showSDLogout = true;
              }
            } else {
              let name = " " + data.data.employeeLastName ? data.data.employeeFirstName + " " + data.data.employeeLastName.split('')[0] + "." : '';
              this.dealerAgent = "Logout " + name + "";
              this.showSDLogout = true;
            }
          } else {
            this.dealerName = "";
            this.showSDLogout = false;
          }
        } else {
          this.dealerName = "";
          this.showSDLogout = false;
        }
      }, 100);
    })

    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      this.currentURL = event.url;
      if (event.url === "/checkout") {
        this.isCheckoutPage = true;
      } else {
        this.isCheckoutPage = false;
      }
      if (event.url === "/cart") {
        this.isCartPage = true;
      } else {
        this.isCartPage = false;
      }
      if (event.url === "/help" || event.url === "/help-center") {
        this.isHelpPage = true;
      } else {
        this.isHelpPage = false;
      }

      // Porsche specific drop down menu text
      let _vehicle: Vehicle = JSON.parse(sessionStorage.getItem("vehicle"));
      let _rates: any = JSON.parse(sessionStorage.getItem("rates"));
      let isElectric = (_vehicle && _vehicle.isElectric) ? true : false;

      if (_rates && _rates.data) {
        _rates.data.productDetailsList.forEach((product) => {
          if (product.productName.indexOf("EV") > -1) {
            isElectric = true;
          }
        });
      }

      if (_vehicle && _rates && brandInfo.brand === "Porsche") {
        if (isElectric) {
          this.showPorscheMenuEV = true;
        } else {
          this.showPorscheMenuICE = true;
        }
      }


      // Nissan specific drop down menu text
      setTimeout(() => {
        this.translate.get("NAV").subscribe(nav => {
          this.product1 = nav.PRODUCT1;
          this.product2 = nav.PRODUCT2;
          this.products = nav.PRODUCTS;

          this.compareProduct = nav.PRODUCTS;

          if (this.currentURL.includes("/added-security-plan")) {
            this.currentProductLink = this.product1;
          } else if (this.currentURL.includes("/prepaid-maintenance")) {
            this.currentProductLink = this.product2;
          } else {
            this.currentProductLink = this.products;
          }

        })
      }, 750);

      this.closeNav();
      
    });

    let skipContent = document.querySelector("#skipContent") as HTMLElement;
    skipContent.addEventListener('click', this.skipContent.bind(this));
  }
  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.checkNav();
  }

  ngAfterViewInit() {
    setTimeout(() => {
    this.checkNav();
    });
  }

  checkNav() {
    const width = window.innerWidth;
    if (width < 900 || brandInfo.centerNav) {
      this.showMobile = true;
    } else {
      this.showMobile = false;
    }
    this.closeNav();
  }

  redirectTo(uri) {
    // Reset Modal State
    this.store.dispatch(clear());
    this.pageService.redirectTo(uri);
  }

  redirectToHome() {
    let _vehicle: Vehicle = JSON.parse(sessionStorage.getItem("vehicle"));
    this.store.dispatch(clear());
    if (brandInfo.brand !== 'Porsche') {
      this.store.dispatch(validateDealerSuccess({}))
    }
    if (sessionStorage.getItem('appFlowState') && sessionStorage.getItem('appFlowState').includes('serviceDrive') && brandInfo.brand !== 'Porsche') {
      this.store.dispatch(clearCart());
    }
    if (_vehicle && _vehicle.isElectric) {
      this.pageService.redirectTo(`/${brandInfo.homePageElectric}`);
    } else {
      this.pageService.redirectTo(`/${brandInfo.homePage}`);
    }
  }

  goToHelp() {
    if (brandInfo.brand == 'NCESI') {
      this.pageService.redirectTo('/help');
    } else {
      this.pageService.redirectTo('/help-center');
    }
  }

  cartModalShow() {
    // this.cartSubscription.unsubscribe();

    //check is current page is /cart
    if (this.currentURL.indexOf("/cart") === -1) {
      this.showCart.emit();
    } 
  }

  signOut(): void {
    let object: OktaUser = {
      id: this.oktaId,
      login: this.login,
      access_token: this.access_token
    }
    this.store.dispatch(resetOktaUser())
    this.store.dispatch(oktaSignOut(object))
    sessionStorage.clear()
  }

  logoutDealerAgent(): void {
    if (brandInfo.brand === 'VCIVW') {
      const _dealer = JSON.parse(sessionStorage.getItem('dealer'))
      _dealer.data.employeeFirstName = ''
      _dealer.data.employeeLastName = ''
      this.store.dispatch(validateDealerSuccess(_dealer))
    } else {
      this.store.dispatch(validateDealerSuccess({}))
      this.store.dispatch(clearCart());
      this.store.dispatch(resetRates());
      this.store.dispatch(removeVehicle());
      window.location.href = "/service-drive"; // need to hard refresh page
    }
  }

  openNav() {
    document.getElementById("mySidenav").style.display = "block";
    setTimeout(() => {
      if (brandInfo.centerNav && brandInfo.leftAlignNav) {
        document.getElementById("mySidenav").style.marginLeft = "0";
      } else {
        document.getElementById("mySidenav").style.marginRight = "0";
      }

    }, 0);
  }

  navigateWithRefresh(route: string) {
    window.location.href = route;
  }

  closeNav() {
    if (brandInfo.centerNav && brandInfo.leftAlignNav) {
      document.getElementById("mySidenav").style.marginLeft = "-300px";
    } else {
      document.getElementById("mySidenav").style.marginRight = "-300px";
    }
    setTimeout(() => {
      document.getElementById("mySidenav").style.display = "none";
    }, 400);
  }

  setCallUs(phone) {
    return this.translate.instant("NAV.CALL_US") + ' - ' + phone;
  }

  skipContent(e) {
    e.preventDefault();
    let maincontent = document.querySelector("#mainContent") as HTMLElement;
    maincontent.scrollIntoView();
    let mainDiv = document.querySelector("router-outlet").nextSibling as HTMLElement; // Only look for the first H1, H2 or H3 in the main page section which is after router-outlet
    let firstContent = mainDiv.querySelector("h1:not(.sr-ignore)") as HTMLElement; // Look for H1s first - All pages should have an H1 or H2 in the main section
    let firstContentOther = mainDiv.querySelector("div[aria-level='1']") as HTMLElement;
    if (firstContentOther) { firstContent = firstContentOther };
    if (firstContent) {
      let ti = firstContent.closest("[tabindex]") as HTMLElement;
      if (ti) {
        ti.focus();
      }
    } else {
      firstContent = mainDiv.querySelector("h2:not(.sr-ignore)") as HTMLElement; // Look for H2s second - All pages should have an H1 or H2 in the main section
      if (firstContent) {
        let ti = firstContent.closest("[tabindex]") as HTMLElement;
        if (ti) {
          ti.focus();
        }
      } else {
        firstContent = mainDiv.querySelector("h3:not(.sr-ignore)") as HTMLElement; // Look for H3s last - in case there is no H1 or H2
        if (firstContent) {
          let ti = firstContent.closest("[tabindex]") as HTMLElement;
          if (ti) {
            ti.focus();
          }
        }
      }
    }
  }
}
