import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Observable, throwError } from "rxjs";
import { map, switchMap, catchError } from "rxjs/operators";
import { Action, Store } from "@ngrx/store";
import { Injectable } from "@angular/core";
import * as ActionConfigurationManager from '../_actions/config.actions';
import { getDiscountFailed, getDiscountSuccess } from "../_actions/discount.actions";
import { OrderService } from "../_services/order.service";
import { TaxRequest } from "../_models/tax";
import brandInfo from '../../environments/brand.json'
import { submitVertexTax } from "../_actions/vertex-tax.actions";
import { submitTax } from "../_actions/tax.actions";
import { Vehicle } from "../_models/vehicle";
import { Cart } from "../_models/cart";
import { updateCartSuccess } from "../_actions/cart.actions";
import { RatesResponse } from "../_models/rates";

@Injectable()
export class DiscountEffects {
    public abandonedCartTrigger: Observable<Action> = createEffect(() =>
        this.actions$
            .pipe(
                ofType(ActionConfigurationManager.GET_DISCOUNT),
                switchMap((action) => this.orderService.getDiscount(action)
                    .pipe(
                        map((response: any) => {
                            const _cart: Cart = JSON.parse(sessionStorage.getItem("cart"));
                            const registration = JSON.parse(sessionStorage.getItem("registration"));
                            const _vehicle: Vehicle = JSON.parse(sessionStorage.getItem("vehicle"));

                            let price = Number(response.data.discountedAmount)
                            let surchargeAmount = 0

                            if (_cart.commercialUse) {
                                surchargeAmount += this.getSurchargeOption('Professional Services')
                            }

                            if (_cart.liftKit) {
                                surchargeAmount += this.getSurchargeOption('Lift Kit')
                            }

                            _cart.subtotal = price + surchargeAmount
                            _cart.savings = response.data.savings;
                            _cart.discountName = response.data.name;
                            this.store.dispatch(updateCartSuccess(_cart))

                            console.log('brandInfo', brandInfo)

                            const taxRequest: TaxRequest = {
                                opportunityId: null,
                                brandName: brandInfo.brand,
                                firstName: "GuestFirst",
                                lastName: "GuestLast",
                                city: registration ? registration.city : '',
                                country: brandInfo.country,
                                email: "noreply@sgintl.com",
                                postalCode: registration ? registration.zip : '',
                                state: registration ? registration.state : _vehicle.registeredState,
                                street1: registration ? registration.address : '',
                                vin: _vehicle.vin,
                                downPayment: _cart.downPayment,
                                installments: _cart.numberOfMonthlyPayments,
                                items: [],
                                phoneNumber: null
                            }



                            let item = {
                                productName: _cart.plans[0].ratesProductName,
                                productSKU: _cart.plans[0].productSkuId,
                                quantity: 1,
                                unitPrice: _cart.subtotal,
                                productSfId: _cart.plans[0].productSkuId,
                                productCode: _cart.plans[0].productCode,
                                contractNumber: _cart.plans[0].ratesProductName,
                            };

                            taxRequest.items.push(item);

                            if (brandInfo.taxService === "Vertex") {
                                this.store.dispatch(submitVertexTax(taxRequest));
                            } else {
                                console.log('goToBilling submitTax');

                                this.store.dispatch(submitTax(taxRequest));
                            }

                            return getDiscountSuccess(response)
                        }),
                        catchError((error) => {
                            this.store.dispatch(getDiscountFailed())
                            return throwError(error)
                        })
                    )
                )
            )
    )

    getSurchargeOption(option) {
        let _rates: RatesResponse = JSON.parse(sessionStorage.getItem("rates"));
        for (let i = 0; i < _rates.data.productDetailsList[0].surcharge.surchargeOption.length; i++) {
          if (_rates.data.productDetailsList[0].surcharge.surchargeOption[i].label.enLabel.toLowerCase().includes(option.toLowerCase()))
            return Number(_rates.data.productDetailsList[0].surcharge.surchargeOption[i].surchargeAmount)
        }
      }

    constructor(
        private actions$: Actions,
        private orderService: OrderService,
        public store: Store<{}>
    ) { }
}
