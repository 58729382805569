<div style="overflow-y: auto" mdbModal #cart="mdbModal" class="cart modal fade right minHeight" id="cartModal" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" [config]="{ backdrop: false, ignoreBackdropClick: true }">
    <div class="modal-dialog modal-side modal-top-right modal-notify modal-info" role="document">
      <div class="modal-content" tabindex="0" role="region" aria-live="assertive">
        <div class="modal-header">
          <h4><mdb-icon fas icon="shopping-cart mr-3"></mdb-icon> <strong role="heading">Your Shopping Cart</strong></h4>
          <app-close-button (callbackFunction)="cart.hide()"></app-close-button>
        </div>
        <div class="modal-body" tabindex="0" id="shopping-cart-panel">
          <div class="row" *ngIf="!isCart">
            <div class="media mt-4 mb-3">
              <div class="media-body text-md-left ml-md-3 ml-0">
                <div class="row">
                  <div class="row p-4">
                    <p>Your shopping cart is empty</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row" *ngIf="isCart">
            <div class="w-100 float-left" *ngIf="cart$ | async as cart">
              <div class="your-vehicle pb-1 pt-1">
                <div class="vehicle-info d-flex align-self-stretch">
                  <div class="image-clip w-100" *ngIf="vehicle$ | async as vehicle">
                    <img *ngIf="vehicle.image" class="img-fluid" [src]="vehicle.image" alt="{{ vehicle.year }} {{ vehicle.make }} {{ vehicle.model }} {{ vehicle.trim }}" />
                  </div>
                  <div class="cart-item-body w-100" *ngIf="vehicle$ | async as vehicle">
                    <div class="d-flex align-self-stretch justify-content-between">
                      <div class="font-weight-bold">{{ vehicle.year }} {{ vehicle.make }} {{ vehicle.model }} {{ vehicle.trim }}</div>
                    </div>
                    <div class="description" *ngIf="registration$ | async as registration">{{ registration.city }}, {{ registration.state }}</div>
                    <div class="description">VIN: {{ vehicleVin }}</div>
                    <div class="description">{{ vehicle.mileage }} {{ mileage }}</div>
                  </div>
                </div>
              </div>

              <div class="pb-2 pt-3 mt-3">
                <div>
                  <span *ngIf="(isBrand === 'GMC' || isBrand === 'Chevrolet' || isBrand === 'Buick' || isBrand === 'Cadillac')" class="overline">AGREEMENT DATE</span>
                  <span *ngIf="!(isBrand === 'GMC' || isBrand === 'Chevrolet' || isBrand === 'Buick' || isBrand === 'Cadillac')" class="overline">COVERAGE START DATE</span>
                  <br />
                    <mdb-icon far icon="calendar" aria-hidden="true"></mdb-icon><span class="pl-2">{{currentDate | date: 'shortDate'}}</span>
                </div>
              </div>

              <hr />
  
              <div tabindex="0" class="pt-4 mt-4 overline">
                {{ 'CART_CHECKOUT.ITEMS_IN_CART' | translate }}
              </div>

              <div class="media mt-4 mb-3" [ngClass]="{'hidden': cart$ === null}">
                <div class="media-body text-md-left" *ngIf="(cart$ | async) as cart" tabindex="0" role="region">
                  <h5 class="mt-0 font-weight-bold pl-3" *ngIf="(plan$ | async) as plan">{{cart.shoppingCartData.lineItems[0].itemName}}</h5>
                  <div class="row">
                    <div class="col-12">
                      <div class="d-flex align-items-baseline justify-content-between w-100">
                        <span>{{cart.plans[0].planName}}</span>
                        <span tabindex="0" ><strong>${{cart.plans[0].planPrice | number: '.2'}}</strong></span>
                      </div>
                      <span tabindex="0" [id]="cart.plans[0].skuId">{{cart.plans[0].planTerms.yearsMileage}} <app-tooltip message="{{setTooltip(plan.productCode)}}"></app-tooltip></span> <br />
                      <span>${{cart.deductible | number:    '.2'}} {{ 'CART_CHECKOUT.DEDUCTIBLE_OCCURENCE' | translate }}</span> <br />
                      <span *ngIf="cart.commercialUse && isBrand === 'Vroom'">
                        Light Duty Commercial Use Coverage: Yes
                        <app-tooltip message="{{term_length_tooltip}}"></app-tooltip>
                      </span>
                      <span *ngIf="!cart.commercialUse && isBrand === 'Vroom'">
                        Light Duty Commercial Use Coverage: No
                        <app-tooltip message="{{term_length_tooltip}}"></app-tooltip>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <hr class="fade-line" />
              <div class="overline">
                {{ 'CART_CHECKOUT.PAYMENT_SUMMARY' | translate }}:
              </div>
              <div class="subtotal d-flex align-items-baseline justify-content-between">
                <span class="font-weight-bold">{{ 'CART_CHECKOUT.SUBTOTAL' | translate }}:</span>
                <span class="font-weight-bold">${{cart.subtotal | number: '.2'}}</span>
              </div>
              <div class="tax d-flex align-items-baseline justify-content-between">
                <span>Applicable Tax:</span>
                <span>${{cart.estimatedTax | number: '.2'}}</span>
              </div>

              <div class="total">
                <div class="d-flex align-items-baseline justify-content-between">
                  <span class="font-weight-bold">ESTIMATED TOTAL:</span>
                  <span class="font-weight-bold">${{cart.grandTotal | number: '.2'}}</span>
                </div>
              </div>
              <div class="tax d-flex align-items-baseline justify-content-between" *ngIf="cart.numberOfMonthlyPayments !== '1'">
                <span>Initial Payment Amount:</span>
                <span>${{cart.downPaymentWithTax | number: '.2'}}</span>
              </div>
              <div class="tax d-flex align-items-baseline justify-content-between" *ngIf="cart.numberOfMonthlyPayments !== '1'">
                <span>Payment Plan:</span>
                <span>{{cart.numberOfMonthlyPayments}} monthly payments of ${{cart.monthlyPayment | number: '.2'}}</span>
              </div>
              <hr />
              <div class="total-today pt-2 pb-4">
                <div class="d-flex align-items-baseline ">
                  <span class="w-50 font-weight-bold">TOTAL DUE TODAY:</span>
                  <div class="w-50">
                    <span class="font-weight-bold text-right float-right" *ngIf="cart.numberOfMonthlyPayments !== '1'">${{cart.downPaymentWithTax | number: '.2'}}</span>
                    <span class="font-weight-bold text-right float-right" *ngIf="cart.numberOfMonthlyPayments === '1'">${{cart.grandTotal | number: '.2'}}</span><br />
                  </div>
                </div>
                <div class="total-today-note text-right" *ngIf="cart.numberOfMonthlyPayments !== '1'">{{cart.numberOfMonthlyPayments}} remaining payments of ${{cart.monthlyPayment | number: '.2'}}</div>
                <!-- <div class="total-today-note text-right" *ngIf="cart.numberOfMonthlyPayments === '1'">(1 payment)</div> -->
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button mdbBtn type="button" role="button" tabindex="0" class="btn primary-btn w-100" (click)="cart.hide()" [disabled]="isCart === false" routerLink="/cart" routerLinkActive="active" mdbWavesEffect>Go to Cart <mdb-icon fas icon="arrow-right"></mdb-icon></button>
        </div>
      </div>
    </div>
  </div>
