import { Component, DoCheck, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, QueryList, ViewChild, ViewChildren } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { MDBModalRef, MDBModalService } from "ng-uikit-pro-standard";
import { Subscription } from "rxjs";
import { AppConfigService } from "src/app/appconfig/appconfig.service";
import { ModalInfoComponent } from "src/app/modal-info/modal-info.component";
import { saveCart } from "src/app/_actions/cart.actions";
import { Opportunity } from "src/app/_models/account";
import { Cart, CartPlan, LineItems, PlanTerms, ShoppingCart } from "src/app/_models/cart";
import { Lead } from "src/app/_models/lead";
import { Plan } from "src/app/_models/plan";
import { RatesResponse } from "src/app/_models/rates";
import { Vehicle } from "src/app/_models/vehicle";
import { ProductTabsetService } from "src/app/_services/product-tabset.service";
import brandInfo from "src/environments/brand.json";
import moment from 'moment'
import localeFr from '@angular/common/locales/fr';
import localeEn from '@angular/common/locales/en';
import { registerLocaleData } from '@angular/common';

@Component({
  selector: "app-coverage-select",
  templateUrl: "./coverage-select.component.html",
  styleUrls: ["./coverage-select.component.scss"],
})
export class CoverageSelectComponent implements OnInit, OnChanges, OnDestroy {
  @Input() plans: any;
  @Input() chosenPlan: any;
  @Input() changeTerms: boolean;
  @Input() _deductible: string;
  @Input() index: number
  @Input() _commercial_use_toggle: boolean = false
  @Input() _snow_plow_toggle: boolean  = false
  @Input() _grey_market_toggle: boolean  = false
  @Input() _lift_kit_toggle: boolean  = false
  @Output() chosenTerm = new EventEmitter<any>();
  @Output() emitChangeTerms = new EventEmitter<any>();
  @Output() emitAddToCart = new EventEmitter<string>();

  termsAndRates: any = [];
  coverageTermsForm: UntypedFormGroup;
  initialized: boolean;
  currentPlanIndex: number;
  check: any;
  modalRef: MDBModalRef;
  commercial_use_tooltip = "";
  term_length_tooltip = "";
  snow_plow_tooltip = "";
  lift_kit_tooltip = "";
  appConfigSubscription: Subscription;
  mileage: string;
  showLiftKit = false;
  showSnowPlow = false;
  showNCESITerm = false;
  grey_market_tooltip: string;
  expireMileage: number;
  expireDate: any;
  preferredLanguage: string;
  isBrand: string;

  constructor(public store: Store<{}>, public router: Router, private modalService: MDBModalService, private appConfig: AppConfigService, private translate: TranslateService, private productTabSetService: ProductTabsetService) { }

  ngOnInit(): void {
    console.log('this.chosenPlan.planTerms', this.chosenPlan ? this.chosenPlan.planTerms : null)
    console.log("coverage-select ngOnInit")

    this.isBrand = brandInfo.brand

    this.coverageTermsForm = new UntypedFormGroup({
      terms: new UntypedFormControl(null, Validators.required),
      commercialUse: new UntypedFormControl(false),
      liftKit: new UntypedFormControl(false),
      snowPlow: new UntypedFormControl(false),
      greyMarket: new UntypedFormControl(false)
    });

    if (this.coverageTermsForm) {
      this.coverageTermsForm.patchValue({ terms: 0 });
    }

    // Get Commercial Tooltip from Prismic
    this.appConfigSubscription = this.appConfig.getSettings().subscribe((result) => {
      this.commercial_use_tooltip = this.appConfig.getCommercialUseTooltip();
      this.term_length_tooltip = this.appConfig.getTermLengthTooltip();
      this.snow_plow_tooltip = this.appConfig.getSnowPlowTooltip();
      this.lift_kit_tooltip = this.appConfig.getLiftKitTooltip();
      this.grey_market_tooltip = this.appConfig.getGreyMarketTooltip()
    });

    // Set translation
    this.translate.get("GENERIC.MILEAGE_PLURAL").subscribe((mileage) => {
      this.mileage = mileage.toLowerCase();
    });

    // TODO set these to pull from API
    // if ((brandInfo.brand === "Chevrolet") ||
    // (brandInfo.brand === "GMC")) {
    // this.showLiftKit = true;
    // this.showSnowPlow = true;
    // }

    if (brandInfo.coverageBasedOnISD) {
      const rates: RatesResponse = JSON.parse(sessionStorage.getItem('rates'))
      if (brandInfo.brand === 'NCESI' && !(this.router.url.includes('pmp'))) {
        let _vehicle: Vehicle = JSON.parse(sessionStorage.getItem('vehicle'))
        if (_vehicle && _vehicle?.inserviceDate !== null && _vehicle.inserviceDate !== '') {
          this.setStateCoverageEstimate();
        }
      }

      if ((brandInfo.brand === 'BMW' || brandInfo.brand === 'MINI') && rates.data.productDetailsList[0].productPlanList[0].planName.toLocaleLowerCase().includes('in warranty')) {
        let _vehicle: Vehicle = JSON.parse(sessionStorage.getItem('vehicle'))
        if (_vehicle && _vehicle?.inserviceDate !== null && _vehicle.inserviceDate !== '') {
          this.setStateCoverageEstimate();
        }
      }

    }

    this.preferredLanguage = sessionStorage.getItem("preferredLanguage") ? sessionStorage.getItem("preferredLanguage") : brandInfo.language;
    registerLocaleData(localeFr, 'fr');
    registerLocaleData(localeEn, 'en-ca');
    registerLocaleData(localeEn, 'en-us');

  }

  ngAfterViewInit() {
    setTimeout(() => {
      console.log('PLAN TERMS: ', this.chosenPlan.planTerms)
      const program = JSON.parse(sessionStorage.getItem("presetProgram"))
      if (program && program.year && program.mileage) {
        this.chosenPlan.planTerms.forEach(plan => { // "3 YEARS, 60,000 MILES"
          let planYear = plan.label.split(' ')[0]
          let planMileage = plan.label.split(' ')[2].replace(',','')
          if (planYear === program.year && planMileage === program.mileage.replace(',','')) {
            this.coverageTermsForm.controls.terms.patchValue(plan.value)
            this.updatePricing();
          }
        })
      }
    }, 0)
  }

  setTooltip(tip) {
    return this.appConfig.getTermLengthTooltip(tip)
  }

  ngOnChanges() {
    console.log('Index seen', this.index)
    setTimeout(() => {
      if (this.index || this.index == 0) {
        this.coverageTermsForm.patchValue({ terms: this.index })
        this.updatePricing();
        if (brandInfo.coverageBasedOnISD) {
          const rates: RatesResponse = JSON.parse(sessionStorage.getItem('rates'))
          
          if (brandInfo.brand === 'NCESI' && !(this.router.url.includes('pmp'))) {
            let _vehicle: Vehicle = JSON.parse(sessionStorage.getItem('vehicle'))
            if (_vehicle && _vehicle?.inserviceDate !== null && _vehicle.inserviceDate !== '') {
              this.setStateCoverageEstimate();
            }
          }

          if ((brandInfo.brand === 'BMW' || brandInfo.brand === 'MINI') && rates.data.productDetailsList[0].productPlanList[0].planName.toLocaleLowerCase().includes('in warranty')) {
            let _vehicle: Vehicle = JSON.parse(sessionStorage.getItem('vehicle'))
            if (_vehicle && _vehicle?.inserviceDate !== null && _vehicle.inserviceDate !== '') {
              this.setStateCoverageEstimate();
            }
          }

        }
      }
    }, 100)

    console.log('Toggle', this._commercial_use_toggle)

    if (this.coverageTermsForm) {
      if (this._commercial_use_toggle) {
        this.coverageTermsForm.controls["commercialUse"].setValue(true)
      } else {
        this.coverageTermsForm.controls["commercialUse"].setValue(false)
      }
      if (this._snow_plow_toggle) {
        this.coverageTermsForm.controls["snowPlow"].setValue(true)
      } else {
        this.coverageTermsForm.controls["snowPlow"].setValue(false)
      }
      if (this._grey_market_toggle) {
        this.coverageTermsForm.controls["greyMarket"].setValue(true)
      } else {
        this.coverageTermsForm.controls["greyMarket"].setValue(false)
      }
      if (this._lift_kit_toggle) {
        this.coverageTermsForm.controls["liftKit"].setValue(true)
      } else {
        this.coverageTermsForm.controls["liftKit"].setValue(false)
      }
    }
  }

  ngOnDestroy() {
    if (this.appConfigSubscription) {
      this.appConfigSubscription.unsubscribe();
    }
  }

  setStateCoverageEstimate() {
    let plan = this.chosenPlan.planTerms[this.index ? this.index : 0].label.split(' ');
    let vehicle: Vehicle = JSON.parse(sessionStorage.getItem("vehicle"));

    if (vehicle.inserviceDate) {
        this.expireDate = new Date(vehicle.inserviceDate.replace(/-/g, '/'));
        this.expireDate = this.expireDate.setFullYear(this.expireDate.getFullYear() + parseInt(plan[0]));
        this.expireDate = moment(this.expireDate).format('MMMM D, yyyy');
        this.expireMileage = plan[2];
    } else {
        this.expireDate = null;
        this.expireMileage = null;
    }

    // now we are Updating showNCESITerm based on expireDate and expireMileage
    this.showNCESITerm = !!this.expireDate && !!this.expireMileage;
}


  updatePricing() {

    let termsData = this.chosenPlan.planTerms;
    console.log('coverage- chosenPlan', this.chosenPlan)

    console.log("coverage-select updatePricing", this.chosenPlan.term.termIndex, termsData)


    this.chosenPlan.term.termIndex = termsData[this.coverageTermsForm.controls["terms"].value].value;

    console.log('coverage- dasdad', this.chosenPlan.term.termIndex)
    this.chosenPlan.term.numberOfPayments = Number(termsData[this.chosenPlan.term.termIndex].paymentTerms[0].split(" ")[0]);
    this.chosenPlan.term.price = (Number(termsData[this.coverageTermsForm.controls["terms"].value].planTermSKURate) / 10).toFixed(2).toString();
    this.chosenPlan.term.totalPrice = termsData[this.coverageTermsForm.controls["terms"].value].planTermSKURate;
    if (brandInfo.frontLoadTaxes) {
      this.chosenPlan.term.payment = ((Number(this.chosenPlan.term.totalPrice)) / Number(this.chosenPlan.term.numberOfPayments)).toFixed(2).toString();
    } else {
      this.chosenPlan.term.payment = ((Number(this.chosenPlan.term.totalPrice) - Number(this.chosenPlan.term.price)) / Number(this.chosenPlan.term.numberOfPayments)).toFixed(2).toString();
    }

    this.chosenPlan.term.productPlanSkuId = termsData[this.coverageTermsForm.controls["terms"].value].productPlanSkuId;
    this.chosenTerm.emit(this.chosenPlan);
  }

  resetPricing() {
    console.log("coverage-select resetPricing")

    let termsData = this.chosenPlan.planTerms;
    this.chosenPlan.term.termIndex = 0;
    this.chosenPlan.term.numberOfPayments = Number(termsData[this.chosenPlan.term.termIndex].paymentTerms[0].split(" ")[0]);
    this.chosenPlan.term.price = (Number(termsData[0].planTermSKURate) / 10).toFixed(2).toString();
    this.chosenPlan.term.totalPrice = termsData[0].planTermSKURate;
    if (brandInfo.frontLoadTaxes) {
      this.chosenPlan.term.payment = ((Number(this.chosenPlan.term.totalPrice)) / Number(this.chosenPlan.term.numberOfPayments)).toFixed(2).toString();
    } else {
      this.chosenPlan.term.payment = ((Number(this.chosenPlan.term.totalPrice) - Number(this.chosenPlan.term.price)) / Number(this.chosenPlan.term.numberOfPayments)).toFixed(2).toString();
    }

    this.chosenPlan.plan.commercialUse = false;

    if (this.coverageTermsForm) {
      this.coverageTermsForm.patchValue({ commercialUse: false });
    }

    this.handleCommercialUse(null);

    this.chosenTerm.emit(this.chosenPlan);
  }


  handleSnowPlow(snowPlow) {
    // TODO
    console.log("coverage-select  handleSnowPlow")


    let termsData = this.chosenPlan.planTerms;
    console.log("termsData", this.chosenPlan, termsData);

    console.log("Handle Snow Plow", snowPlow);


    const price = this.productTabSetService.getPriceForSurcharge('Plow')

    if (snowPlow) {

      this.chosenPlan.term.totalPrice = Number(this.chosenPlan.term.totalPrice) + price;
      this.chosenPlan.term.price = Number(this.chosenPlan.term.totalPrice) / 10;
      if (brandInfo.frontLoadTaxes) {
        this.chosenPlan.term.payment = ((Number(this.chosenPlan.term.totalPrice)) / Number(this.chosenPlan.term.numberOfPayments)).toFixed(2).toString();
      } else {
        this.chosenPlan.term.payment = ((Number(this.chosenPlan.term.totalPrice) - Number(this.chosenPlan.term.price)) / Number(this.chosenPlan.term.numberOfPayments)).toFixed(2).toString();
      }

      this.chosenPlan.term.snowPlowSelected = snowPlow;
      this.chosenTerm.emit(this.chosenPlan);

    } else if (!snowPlow && this.chosenPlan.term.totalPrice !== termsData[this.coverageTermsForm.controls["terms"].value].planTermSKURate) {

      this.chosenPlan.term.totalPrice = Number(this.chosenPlan.term.totalPrice) - price
      this.chosenPlan.term.price = Number(this.chosenPlan.term.totalPrice) / 10;
      if (brandInfo.frontLoadTaxes) {
        this.chosenPlan.term.payment = ((Number(this.chosenPlan.term.totalPrice)) / Number(this.chosenPlan.term.numberOfPayments)).toFixed(2).toString();
      } else {
        this.chosenPlan.term.payment = ((Number(this.chosenPlan.term.totalPrice) - Number(this.chosenPlan.term.price)) / Number(this.chosenPlan.term.numberOfPayments)).toFixed(2).toString();
      }

      this.chosenPlan.term.snowPlowSelected = snowPlow;
      this.chosenTerm.emit(this.chosenPlan);

    }
  }

  handleLiftKit(liftKit) {
    console.log("coverage-select  handleLiftKit")


    let termsData = this.chosenPlan.planTerms;
    console.log("termsData", this.chosenPlan, termsData);

    console.log("Handle Lift Kit", liftKit);
    let _rates: RatesResponse = JSON.parse(sessionStorage.getItem("rates"));

    const price = this.productTabSetService.getPriceForSurcharge('Lift Kit')

    console.log(price)

    if (liftKit) {

      this.chosenPlan.term.totalPrice = Number(this.chosenPlan.term.totalPrice) + price;
      this.chosenPlan.term.price = Number(this.chosenPlan.term.totalPrice) / 10;
      if (brandInfo.frontLoadTaxes) {
        this.chosenPlan.term.payment = ((Number(this.chosenPlan.term.totalPrice)) / Number(this.chosenPlan.term.numberOfPayments)).toFixed(2).toString();
      } else {
        this.chosenPlan.term.payment = ((Number(this.chosenPlan.term.totalPrice) - Number(this.chosenPlan.term.price)) / Number(this.chosenPlan.term.numberOfPayments)).toFixed(2).toString();
      }

      this.chosenPlan.term.liftKitSelected = liftKit;
      this.chosenTerm.emit(this.chosenPlan);

    } else if (!liftKit && this.chosenPlan.term.totalPrice !== termsData[this.coverageTermsForm.controls["terms"].value].planTermSKURate) {

      this.chosenPlan.term.totalPrice = Number(this.chosenPlan.term.totalPrice) - price;
      this.chosenPlan.term.price = Number(this.chosenPlan.term.totalPrice) / 10;
      if (brandInfo.frontLoadTaxes) {
        this.chosenPlan.term.payment = ((Number(this.chosenPlan.term.totalPrice)) / Number(this.chosenPlan.term.numberOfPayments)).toFixed(2).toString();
      } else {
        this.chosenPlan.term.payment = ((Number(this.chosenPlan.term.totalPrice) - Number(this.chosenPlan.term.price)) / Number(this.chosenPlan.term.numberOfPayments)).toFixed(2).toString();
      }

      this.chosenPlan.term.liftKitSelected = liftKit;
      this.chosenTerm.emit(this.chosenPlan);

    }
  }

  handleGreyMarket(greyMarket) {
    console.log("coverage-select  handleGreyMarket")


    let termsData = this.chosenPlan.planTerms;
    console.log("termsData", this.chosenPlan, termsData);

    console.log("Handle Grey Market", greyMarket);
    let _rates: RatesResponse = JSON.parse(sessionStorage.getItem("rates"));

    const price = this.productTabSetService.getPriceForSurcharge('Grey Market')

    if (greyMarket) {

      this.chosenPlan.term.totalPrice = Number(this.chosenPlan.term.totalPrice) + price;
      this.chosenPlan.term.price = Number(this.chosenPlan.term.totalPrice) / 10;
      if (brandInfo.frontLoadTaxes) {
        this.chosenPlan.term.payment = ((Number(this.chosenPlan.term.totalPrice)) / Number(this.chosenPlan.term.numberOfPayments)).toFixed(2).toString();
      } else {
        this.chosenPlan.term.payment = ((Number(this.chosenPlan.term.totalPrice) - Number(this.chosenPlan.term.price)) / Number(this.chosenPlan.term.numberOfPayments)).toFixed(2).toString();
      }

      this.chosenPlan.term.greyMarketSelected = greyMarket;
      this.chosenTerm.emit(this.chosenPlan);

    } else if (!greyMarket && this.chosenPlan.term.totalPrice !== termsData[this.coverageTermsForm.controls["terms"].value].planTermSKURate) {

      this.chosenPlan.term.totalPrice = Number(this.chosenPlan.term.totalPrice) - price;
      this.chosenPlan.term.price = Number(this.chosenPlan.term.totalPrice) / 10;
      if (brandInfo.frontLoadTaxes) {
        this.chosenPlan.term.payment = ((Number(this.chosenPlan.term.totalPrice)) / Number(this.chosenPlan.term.numberOfPayments)).toFixed(2).toString();
      } else {
        this.chosenPlan.term.payment = ((Number(this.chosenPlan.term.totalPrice) - Number(this.chosenPlan.term.price)) / Number(this.chosenPlan.term.numberOfPayments)).toFixed(2).toString();
      }

      this.chosenPlan.term.greyMarketSelected = greyMarket;
      this.chosenTerm.emit(this.chosenPlan);

    }
  }

  handleCommercialUse(commercialUse) {
    console.log("coverage-select  handleCommercialUse")


    let termsData = this.chosenPlan.planTerms;
    console.log("termsData", this.chosenPlan, termsData);

    console.log("Handle Commercial Use", commercialUse);
    let _rates: RatesResponse = JSON.parse(sessionStorage.getItem("rates"));

    const price = this.productTabSetService.getPriceForSurcharge(['GMC', 'Cadillac', 'Chevrolet', 'Buick'].includes(brandInfo.brand) ? 'Professional Services' : 'Light Duty')

    console.log(this.coverageTermsForm.controls["commercialUse"].value)

    if (commercialUse) {

      this.chosenPlan.term.totalPrice = Number(this.chosenPlan.term.totalPrice) + price;
      this.chosenPlan.term.price = Number(this.chosenPlan.term.totalPrice) / 10;
      if (brandInfo.frontLoadTaxes) {
        this.chosenPlan.term.payment = ((Number(this.chosenPlan.term.totalPrice)) / Number(this.chosenPlan.term.numberOfPayments)).toFixed(2).toString();
      } else {
        this.chosenPlan.term.payment = ((Number(this.chosenPlan.term.totalPrice) - Number(this.chosenPlan.term.price)) / Number(this.chosenPlan.term.numberOfPayments)).toFixed(2).toString();
      }

      this.chosenPlan.term.commercialUseSelected = commercialUse;
      this.chosenTerm.emit(this.chosenPlan);

    } else if (!commercialUse && this.chosenPlan.term.totalPrice !== termsData[this.coverageTermsForm.controls["terms"].value].planTermSKURate) {

      this.chosenPlan.term.totalPrice = Number(this.chosenPlan.term.totalPrice) - price;
      this.chosenPlan.term.price = Number(this.chosenPlan.term.totalPrice) / 10;
      if (brandInfo.frontLoadTaxes) {
        this.chosenPlan.term.payment = ((Number(this.chosenPlan.term.totalPrice)) / Number(this.chosenPlan.term.numberOfPayments)).toFixed(2).toString();
      } else {
        this.chosenPlan.term.payment = ((Number(this.chosenPlan.term.totalPrice) - Number(this.chosenPlan.term.price)) / Number(this.chosenPlan.term.numberOfPayments)).toFixed(2).toString();
      }

      this.chosenPlan.term.commercialUseSelected = commercialUse;
      this.chosenTerm.emit(this.chosenPlan);

    }
  }

  convertTermLabel(term:string) {
    term = term.toUpperCase();
    var years1 = (term.includes(' YEARS') ? term.split(' YEARS') : term.includes(' YEAR(S)') ? term.split(' YEAR(S)') : term.split(' YEAR'));
    var years2 = (term.includes(' YEARS, ') ? term.split(' YEARS, ') : term.includes(' YEAR(S), ') ? term.split(' YEAR(S), ') : term.split(' YEAR, '));
    return (years1[0]) + ' ' + this.translate.instant("GENERIC.YEARS") + ' ' + this.translate.instant("RATES_PAGE.OR") + ' ' + years2[1].split(' ')[0] + ' ' + this.translate.instant("GENERIC.MILEAGE_PLURAL").toLowerCase()
  }

  showInfoModal(content: String) {
    const modalOptions = {
      class: "modal-md",
      ignoreBackdropClick: true,
      containerClass: "",
      animated: true,
      data: {
        title: "What is Professional Services Coverage?",
        content:
          "Professional Services means a company or pool vehicle used for the following commercial purposes: business travel, site inspection, light delivery, service or repair, and snow removal, provided that the Covered Vehicle is equipped for snow removal, as recommended by the manufacturer. Professional Services is considered to be a Prohibited Commercial Purpose, unless the Optional Professional Services box was selected on the Registration Page.",
      },
    };

    this.modalRef = this.modalService.show(ModalInfoComponent, modalOptions);
  }

  addToCart() {
    console.log("coverage-select addToCart")

    this.emitAddToCart.emit("addToCart");
  }

  getFormattedDate() {
    let d = new Date();
    let year = d.getFullYear().toString();
    let day = d.getDate().toString();
    let month = d.getMonth().toString();

    if (month === "0") {
      month = "01";
    } else if (month === "1") {
      month = "02";
    } else if (month === "2") {
      month = "03";
    } else if (month === "3") {
      month = "04";
    } else if (month === "4") {
      month = "05";
    } else if (month === "5") {
      month = "06";
    } else if (month === "6") {
      month = "07";
    } else if (month === "7") {
      month = "08";
    } else if (month === "8") {
      month = "09";
    }

    if (day === "1") {
      day = "01";
    } else if (day === "2") {
      day = "02";
    } else if (day === "3") {
      day = "03";
    } else if (day === "4") {
      day = "04";
    } else if (day === "5") {
      day = "05";
    } else if (day === "6") {
      day = "06";
    } else if (day === "7") {
      day = "07";
    } else if (day === "8") {
      day = "08";
    } else if (day === "9") {
      day = "09";
    }
    let x = d.toISOString().split("T");
    return x[0] + " " + x[1].substr(0, 8);
  }
}
