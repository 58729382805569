import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { VerifyAccountResponse } from 'src/app/_models/verify-account';
import { PreferenceCenterURLParams } from '../_models/urlParams';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { showPreferenceCenterInlineLoader, preferenceCenterEmailFound, hidePreferenceCenterInlineLoader, preferenceCenterNoEmailFound } from '../_actions/preference-center.actions';
import { Observable } from 'rxjs';
import { showInlineLoader, hideInlineLoader } from '../_actions/inline-loader.actions';
import { AppConfigService } from '../appconfig/appconfig.service';
import brandInfo from "src/environments/brand.json";

@Component({
  selector: 'app-preference',
  templateUrl: './preference.component.html',
  styleUrls: ['./preference.component.scss']
})

@Input()

export class PreferenceComponent implements OnInit {
  urlParameters: PreferenceCenterURLParams = {
    emailId: null,
    data: null,
    SubKey: null,
    JobID: null,
    BatchID: null
  }
  preferenceCenterInlineLoader$: Observable<any>;
  preferenceCenter$: Observable<any>;
  urlSafe: SafeResourceUrl
  branding: any;

  constructor(
    public sanitizer: DomSanitizer,
    private route: ActivatedRoute,
    private appConfig: AppConfigService,
    private router: Router,
    private store: Store<{
      inlineLoaderPreferenceCenter: any,
      preferenceCenter: any
    }>
  ) {
    this.route.queryParams.subscribe(params => {
      this.urlParameters.emailId = params["email"];
      this.urlParameters.data = params["data"];
      this.urlParameters.SubKey = params["SubKey"];
      this.urlParameters.JobID = params["JobID"];
      this.urlParameters.BatchID = params["BatchID"];
    })
  }

  ngOnInit() {
    this.appConfig.getSettings().subscribe((result) => {
      this.branding = result;
    }, (error) => {});

    this.preferenceCenterInlineLoader$ = this.store.select(store => store.inlineLoaderPreferenceCenter);
    this.preferenceCenter$ = this.store.select(store => store.preferenceCenter);

    this.store.dispatch(showInlineLoader());

    if (this.urlParameters.data) {
      if (brandInfo.hubspotPreferencesId) {
        const e = document.createElement("iframe");
        e.src = `https://hs-47077450.s.hubspotemail.net/hs/preferences-center/${brandInfo.hubspotPreferencesId}/en/page?data=` + this.urlParameters.data;
        e.width = "100%";
        e.height = "500";
        e.style.border = "none";
        document.getElementById("hs-custom-preferences").appendChild(e);
        this.store.dispatch(preferenceCenterEmailFound());
      } else {
        this.store.dispatch(preferenceCenterNoEmailFound());
      }
    } else {
      let paramsPresent = this.urlParameters.emailId && this.urlParameters.SubKey && this.urlParameters.JobID && this.urlParameters.BatchID;
      let paramsPresentOld = this.urlParameters.emailId ? true : false;

      if (paramsPresent) {
        let baseSalesforce = `${brandInfo.preferenceCenterUrl}`;
        console.log('New URL Being Used', baseSalesforce);
        let url: string = `${baseSalesforce}?email=${this.urlParameters.emailId}
                            &SubKey=${this.urlParameters.SubKey}
                            &JobId=${this.urlParameters.JobID}
                            &BatchId=${this.urlParameters.BatchID}`;
        this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(url);
        console.log(this.urlSafe);
        this.store.dispatch(preferenceCenterEmailFound());
      } else if (paramsPresentOld) {
        let url = `${brandInfo.preferenceCenterUrl}?subscriber=` + this.urlParameters.emailId;
        console.log('Old URL Being Used');
        this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(url);
        console.log(this.urlSafe);
        this.store.dispatch(preferenceCenterEmailFound());
      } else {
        console.log('No parameters found');
        this.store.dispatch(preferenceCenterNoEmailFound());
      }
    }
    this.store.dispatch(hideInlineLoader());
  }

  retry() {
    console.log('Retry Hit');
    this.ngOnInit();
  }
}