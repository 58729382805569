import { BrowserWarningComponent } from './browser-warning/browser-warning.component';
import { LegalNoticeComponent } from './legal/legal-notice/legal-notice.component';
import { AccessibilityComponent } from './legal/accessibility/accessibility.component';
import { PrivacyComponent } from './legal/privacy/privacy.component';
import { NgModule, inject } from '@angular/core';
import { Routes, RouterModule, ExtraOptions, Router, ActivatedRouteSnapshot } from '@angular/router';
import { ShoppingCartComponent } from './shopping-cart/shopping-cart.component';
import { ConfirmationComponent } from './confirmation/confirmation.component';

import { CartGuard } from './_guards/cart.guard';
import { QuicklinkModule, QuicklinkStrategy } from 'ngx-quicklink';
import { ConfirmationGuard } from './_guards/confirmation.guard';
import { MyAccountVerifyComponent } from './my-account/my-account-verify/my-account-verify.component';
import { PreferenceComponent } from './preference/preference.component';
import { ContentBlockComponent } from './slices/content-block/content-block.component';
import { SlicePreviewComponent } from './slices/slice-preview/slice-preview.component';
import { ProductHomeComponent } from './product-home/product-home.component';
import brandInfo from "src/environments/brand.json";
import { OktaAuthGuard, OktaCallbackComponent } from '@okta/okta-angular';
import { ViewContractComponent } from './view-contract/view-contract.component';
import { SessionStorageDataComponent } from './session-storage-data/session-storage-data.component';
import { RedirectComponent } from './redirect/redirect.component';

const routerOptions: ExtraOptions = {
  //scrollPositionRestoration: 'enabled',
  //anchorScrolling: 'enabled',
  onSameUrlNavigation: 'reload', // Always trigger navigation on same URL
  scrollOffset: [0, 64],
  preloadingStrategy: QuicklinkStrategy
};

const routes: Routes = [
  {
    path: '',
    // canActivate: [LeadGuard],

    children: [
      { path: '', component: RedirectComponent },
      {
        path: 'offer',
        component: SessionStorageDataComponent,
      },
      {
        path: 'login/callback',
        component: OktaCallbackComponent
      },
      {
        path: 'page',
        loadChildren: () => import('./product-home/product-home.module').then(m => m.ProductHomeModule)
      },
      {
        path: 'content-block/:uid',
        component: SlicePreviewComponent,
      },
      // {
      //   path: 'slot/countdown',
      //   component: CountdownComponent,
      // },
      {
        path: 'how-vsp-works',
        loadChildren: () => import('./how/how.module').then(m => m.HowModule)
      },
      // {
      //   path: 'home',
      //   loadChildren: () => import('./home/home.module').then(m => m.HomeModule)
      // },
      // {
      //   path: 'prepaid-maintenance',
      //   loadChildren: () => import('./ppm/ppm.module').then(m => m.PpmModule)
      // },
      // {
      //   path: 'verify-vehicle',
      //   loadChildren: () => import('./vehicle/vehicle.module').then(m => m.VehicleModule)
      // },
      {
        path: 'my-account',
        loadChildren: () => import('./my-account/my-account.module').then(m => m.MyAccountModule),
        //canActivate: [OktaAuthGuard]
      },
      {
        path: 'help-center',
        loadChildren: () => import('./support/support.module').then(m => m.SupportModule)
      },
      {
        path: 'loading',
        loadChildren: () => import('./loading/loading.module').then(m => m.LoadingModule)
      },
      {
        path: 'customize-plan',
        loadChildren: () => import('./customize/customize.module').then(m => m.CustomizeModule)
      },
      {
        path: 'cart',
        loadChildren: () => import('./shopping-cart/shopping-cart.module').then(m => m.ShoppingCartModule)
      },
      // {
      //   path: 'search',
      //   component: SearchComponent,
      //   data: { title: 'Search', animation: 'fade' },
      // },
      {
        path: 'preference-center',
        loadChildren: () => import('./preference/preference.module').then(m => m.PreferenceModule),
      },
      {
        path: 'checkout',
        loadChildren: () => import('./checkout/checkout.module').then(m => m.CheckoutModule)
      },
      {
        path: 'documents',
        loadChildren: () => import('./documents-s3/documents.module').then(m => m.DocumentsModule)
      },
      {
        path: 'confirmation',
        loadChildren: () => import('./checkout/checkout.module').then(m => m.CheckoutModule),
      },
      // {
      //   path: 'ui-sandbox',
      //   loadChildren: () => import('./ui-sandbox/ui-sandbox.module').then(m => m.UiSandboxModule)
      // },
      {
        path: 'cms-sandbox',
        loadChildren: () => import('./cms-sandbox/cms-sandbox.module').then(m => m.CmsSandboxModule)
      },
      // {
      //   path: 'srvc-sandbox',
      //   loadChildren: () => import('./srvc-sandbox/srvc-sandbox.module').then(m => m.SrvcSandboxModule)
      // },
      {
        path: 'preview',
        loadChildren: () => import('./preview/preview.module').then(m => m.PreviewModule)
      },
      {
        path: 'legal',
        loadChildren: () => import('./legal/legal.module').then(m => m.LegalModule)
      },
      // {
      //   path: 'emailPreferences',
      //   loadChildren: './email-preferences/email-preferences.module#EmailPreferencesModule'
      // },
    ],
  },
  {
    path: 'getContract/:uuid',
    component: ViewContractComponent
  },
  {
    path: 'verify-account',
    component: MyAccountVerifyComponent,
    data: { title: 'Verify Account', animation: 'fade' },
  },
  {
    path: 'browser-warning',
    loadChildren: () => import('./browser-warning/browser-warning.module').then(m => m.BrowserWarningModule)
  },
  {
    path: ':parenturl/:pageurl',
    component: ProductHomeComponent,
    data: { title: "", animation: "fade", pageType: "", lang: sessionStorage.getItem("preferredLanguage") ? sessionStorage.getItem("preferredLanguage") : brandInfo.language },
  },
  {
    path: ':pageurl',
    component: ProductHomeComponent,
    data: { title: "", animation: "fade", pageType: "", lang: sessionStorage.getItem("preferredLanguage") ? sessionStorage.getItem("preferredLanguage") : brandInfo.language },
  },
  {
    path: '**',
    loadChildren: () => import('./not-found/not-found.module').then(m => m.NotFoundModule)
  }
];

@NgModule({
  imports: [QuicklinkModule, RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule { }
