
<div class="loading" *ngIf="showSpinner">
    <!--Big blue-->
    <div class="preloader-wrapper active indicator">
        <div class="spinner-layer spinner-client-color">
            <div class="circle-clipper left">
                <div class="circle"></div>
            </div> 
            <div class="gap-patch">
                <div class="circle"></div>
            </div>
            <div class="circle-clipper right">
                <div class="circle"></div>
            </div>
        </div>
    </div>
    <div *ngIf="showExtensionMessage" class="loading-text">{{ 'MODAL.STILL_LOADING' | translate }} </div>
</div>
