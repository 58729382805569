import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY, of, throwError } from 'rxjs';
import { map, switchMap, catchError, tap } from 'rxjs/operators';
import { AccountService } from '../_services/account.service';
import { paylinkLoaded, updateBillingStateOn, noAccountFound, loadPaymentHistory, loadPaylink, loadSPPHistory, loadSPPHistorySuccess, loadSPPHistoryFailure} from '../_actions/account.actions'
import * as ActionConfigurationManager from '.././_actions/config.actions';
import { Store } from '@ngrx/store';
import { hideInlineLoader, hideInlineLoaderPayment, hideInlineLoaderContact, hideInlineLoaderBilling } from '../_actions/inline-loader.actions';
import { Router } from '@angular/router';
import { PaymentHistory, PaymentHistoryMonths, PaymentHistoryObject, PaymentHistoryYears } from '../_models/account';
import { Injectable } from '@angular/core';
import brandInfo from "src/environments/brand.json";


@Injectable()

export class PaylinkEffects {

    paymentHistoryObject: PaymentHistoryObject = {
        paymentHistory: []
    }
    paymentHistory: PaymentHistory = {
        months: [],
        year: ''
    }
    paymentHistoryMonths: PaymentHistoryMonths = {
        finalPayment: false,
        status: ''
    }
    paymentHistoryYears: PaymentHistoryYears

    public getPaylink$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadPaylink),
            switchMap((action: any) => this.accountService.get_paylink(action)
                .pipe(
                    map(paylink => {
                        this.paymentHistoryObject = {
                            paymentHistory: []
                        }
                        console.log("paylinkkkkkkkkkkkkkkkkkk", paylink);
                        if (paylink.data &&  !Object.keys(paylink.data).includes('installment')) {
                          console.log("INTHEIFFpaylinkkkkkkkkkkkkkkkkkk", paylink);

                            let firstPaymentDate = new Date(paylink.data.firstPaymentDate.substr(0, 10).toString())
                            let numberOfPayments: number =  brandInfo.frontLoadTaxes ? Number(paylink.data.totalInstallments) - 1 : Number(paylink.data.totalInstallments)
                            let firstPaymentYear = firstPaymentDate.getFullYear()
                            let lastPaymentDate = new Date(firstPaymentDate.setMonth(firstPaymentDate.getMonth() + numberOfPayments))
                            let lastPaymentYear = new Date(firstPaymentDate.setMonth(firstPaymentDate.getMonth() + numberOfPayments - 1)).getFullYear();
                            let firstPaymentMonth: number = Number(paylink.data.firstPaymentDate.substr(5, 2)) - 1

                            console.log(firstPaymentDate, lastPaymentDate)
                            console.log(firstPaymentYear, lastPaymentDate.getFullYear())
                            console.log(firstPaymentDate.getMonth(), lastPaymentDate.getMonth())
                            console.log(lastPaymentDate.getFullYear() - firstPaymentYear)

                            let difference = lastPaymentDate.getFullYear() - firstPaymentYear

                            let status = paylink?.data?.accountStatus

                            if (status === 'Current') {
                                console.log('Current is the status')
                                if (difference === 0) {
                                    console.log('0')
                                    for (let i = 0; i < 2; i++) {
                                        let paymentHistory: PaymentHistory = {
                                            year: '',
                                            months: []
                                        }
                                        paymentHistory.year = firstPaymentYear.toString()
                                        for (let j = 0; j < 12; j++) {
                                            let paymentHistoryMonths: PaymentHistoryMonths = {
                                                finalPayment: false,
                                                status: ''
                                            }
                                            if (i === 0) {
                                                if (j < firstPaymentMonth) {
                                                    paymentHistoryMonths.finalPayment = false;
                                                    paymentHistoryMonths.status = null
                                                }
                                                if (j === firstPaymentMonth) {
                                                    paymentHistoryMonths.finalPayment = false;
                                                    paymentHistoryMonths.status = 'Unpaid'
                                                }
                                                if (j > firstPaymentMonth && j < lastPaymentDate.getMonth()) {
                                                    paymentHistoryMonths.finalPayment = false;
                                                    paymentHistoryMonths.status = 'Unpaid'
                                                }
                                                if (j === lastPaymentDate.getMonth()) {
                                                    paymentHistoryMonths.finalPayment = true;
                                                    paymentHistoryMonths.status = 'Unpaid'
                                                }
                                                if (j > lastPaymentDate.getMonth()) {
                                                    paymentHistoryMonths.finalPayment = false;
                                                    paymentHistoryMonths.status = null
                                                }
                                            } else {
                                                paymentHistoryMonths.finalPayment = false;
                                                paymentHistoryMonths.status = null
                                            }
                                            paymentHistory.months.push(paymentHistoryMonths)
                                        }
                                        if (this.paymentHistoryObject.paymentHistory.length !== 2) {
                                            this.paymentHistoryObject.paymentHistory.push(paymentHistory)
                                        }
                                        firstPaymentYear++
                                    }
                                } else if (difference === 1) {
                                    console.log('1')
                                    for (let i = firstPaymentYear; i <= lastPaymentDate.getFullYear(); i++) {
                                        let paymentHistory: PaymentHistory = {
                                            year: '',
                                            months: []
                                        }
                                        paymentHistory.year = i.toString()
                                        for (let j = 0; j < 12; j++) {
                                            let paymentHistoryMonths: PaymentHistoryMonths = {
                                                finalPayment: false,
                                                status: ''
                                            }
                                            if (i === firstPaymentYear) {
                                                if (j < firstPaymentMonth) {
                                                    paymentHistoryMonths.finalPayment = false;
                                                    paymentHistoryMonths.status = null
                                                }
                                                if (j === firstPaymentMonth) {
                                                    paymentHistoryMonths.finalPayment = false;
                                                    paymentHistoryMonths.status = 'Unpaid'
                                                }
                                                if (j > firstPaymentMonth) {
                                                    paymentHistoryMonths.finalPayment = false;
                                                    paymentHistoryMonths.status = 'Unpaid'
                                                }
                                            } else if (i === lastPaymentDate.getFullYear()) {
                                                if (j < lastPaymentDate.getMonth()) {
                                                    paymentHistoryMonths.finalPayment = false;
                                                    paymentHistoryMonths.status = 'Unpaid'
                                                }
                                                if (j === lastPaymentDate.getMonth()) {
                                                    paymentHistoryMonths.finalPayment = true;
                                                    paymentHistoryMonths.status = 'Unpaid'
                                                }
                                                if (j > lastPaymentDate.getMonth()) {
                                                    paymentHistoryMonths.finalPayment = false;
                                                    paymentHistoryMonths.status = null
                                                }
                                            }
                                            paymentHistory.months.push(paymentHistoryMonths)
                                        }
                                        if (this.paymentHistoryObject.paymentHistory.length !== 3) {
                                            this.paymentHistoryObject.paymentHistory.push(paymentHistory)
                                        }
                                    }
                                } else if (difference > 1) {
                                    console.log('2')
                                    for (let i = firstPaymentYear; i <= lastPaymentDate.getFullYear(); i++) {
                                        let paymentHistory: PaymentHistory = {
                                            year: '',
                                            months: []
                                        }
                                        console.log('First Loop:', i)
                                        paymentHistory.year = i.toString();
                                        console.log('testing')
                                        for (let j = 0; j < 12; j++) {
                                            let paymentHistoryMonths: PaymentHistoryMonths = {
                                                finalPayment: false,
                                                status: ''
                                            }
                                            console.log('Second loop:', j)
                                            if (i === firstPaymentYear) {
                                                if (j < firstPaymentMonth) {
                                                    paymentHistoryMonths.finalPayment = false;
                                                    paymentHistoryMonths.status = null
                                                }
                                                if (j === firstPaymentMonth) {
                                                    paymentHistoryMonths.finalPayment = false;
                                                    paymentHistoryMonths.status = 'Unpaid'
                                                }
                                                if (j > firstPaymentMonth) {
                                                    paymentHistoryMonths.finalPayment = false;
                                                    paymentHistoryMonths.status = 'Unpaid'
                                                }
                                            } else if (i === lastPaymentDate.getFullYear()) {
                                                if (j < lastPaymentDate.getMonth()) {
                                                    paymentHistoryMonths.finalPayment = false;
                                                    paymentHistoryMonths.status = 'Unpaid'
                                                }
                                                if (j === lastPaymentDate.getMonth()) {
                                                    paymentHistoryMonths.finalPayment = true;
                                                    paymentHistoryMonths.status = 'Unpaid'
                                                }
                                                if (j > lastPaymentDate.getMonth()) {
                                                    paymentHistoryMonths.finalPayment = false;
                                                    paymentHistoryMonths.status = null
                                                }
                                            } else {
                                                paymentHistoryMonths.finalPayment = false;
                                                paymentHistoryMonths.status = 'Unpaid'
                                            }
                                            paymentHistory.months.push(paymentHistoryMonths)
                                        }
                                        console.log('PaymentHistory:', paymentHistory)
                                        if (this.paymentHistoryObject.paymentHistory.length !== 4) {
                                            this.paymentHistoryObject.paymentHistory.push(paymentHistory)
                                        }
                                    }
                                }
                                let paymentsMade = paylink.data.paymentsMade
                                for (let i = 0; i < this.paymentHistoryObject.paymentHistory.length; i++) {
                                    for (let j = 0; j < 12; j++) {
                                        if (Number(paymentsMade) > 0) {
                                            console.log(Number(paymentsMade))
                                            if (this.paymentHistoryObject.paymentHistory[i].months[j].status !== null) {
                                                this.paymentHistoryObject.paymentHistory[i].months[j].status = 'Paid'
                                                paymentsMade = Number(paymentsMade) - 1
                                            }
                                        }
                                    }
                                }
                                const reveredPaymentYears = this.paymentHistoryObject.paymentHistory.reverse()
                                this.paymentHistoryObject.paymentHistory = reveredPaymentYears
                                console.log('PaymentHistoryObject:', this.paymentHistoryObject)
                                this.store.dispatch(loadPaymentHistory(this.paymentHistoryObject))
                                this.store.dispatch(hideInlineLoader())
                                this.router.navigate(['/my-account']);
                                return paylinkLoaded(paylink)
                            } else if (status === 'Paid-In-Full' || status === 'Closed/Archived') {
                                console.log('Paid In Full is the status')
                                if (difference === 0) {
                                    console.log('0')
                                    for (let i = 0; i < 2; i++) {
                                        let paymentHistory: PaymentHistory = {
                                            year: '',
                                            months: []
                                        }
                                        paymentHistory.year = firstPaymentYear.toString()
                                        for (let j = 0; j < 12; j++) {
                                            let paymentHistoryMonths: PaymentHistoryMonths = {
                                                finalPayment: false,
                                                status: ''
                                            }
                                            if (i === 0) {
                                                if (j < firstPaymentMonth) {
                                                    paymentHistoryMonths.finalPayment = false;
                                                    paymentHistoryMonths.status = null
                                                }
                                                if (j === firstPaymentMonth) {
                                                    paymentHistoryMonths.finalPayment = false;
                                                    paymentHistoryMonths.status = 'Paid'
                                                }
                                                if (j > firstPaymentMonth && j < lastPaymentDate.getMonth()) {
                                                    paymentHistoryMonths.finalPayment = false;
                                                    paymentHistoryMonths.status = 'Paid'
                                                }
                                                if (j === lastPaymentDate.getMonth()) {
                                                    paymentHistoryMonths.finalPayment = true;
                                                    paymentHistoryMonths.status = 'Paid'
                                                }
                                                if (j > lastPaymentDate.getMonth()) {
                                                    paymentHistoryMonths.finalPayment = false;
                                                    paymentHistoryMonths.status = null
                                                }
                                            } else {
                                                paymentHistoryMonths.finalPayment = false;
                                                paymentHistoryMonths.status = null
                                            }
                                            paymentHistory.months.push(paymentHistoryMonths)
                                        }
                                        if (this.paymentHistoryObject.paymentHistory.length !== 2) {
                                            this.paymentHistoryObject.paymentHistory.push(paymentHistory)
                                        }
                                        firstPaymentYear++
                                    }
                                } else if (difference === 1) {
                                    console.log('1')
                                    for (let i = firstPaymentYear; i <= lastPaymentDate.getFullYear(); i++) {
                                        let paymentHistory: PaymentHistory = {
                                            year: '',
                                            months: []
                                        }
                                        paymentHistory.year = i.toString()
                                        for (let j = 0; j < 12; j++) {
                                            let paymentHistoryMonths: PaymentHistoryMonths = {
                                                finalPayment: false,
                                                status: ''
                                            }
                                            if (i === firstPaymentYear) {
                                                if (j < firstPaymentMonth) {
                                                    paymentHistoryMonths.finalPayment = false;
                                                    paymentHistoryMonths.status = null
                                                }
                                                if (j === firstPaymentMonth) {
                                                    paymentHistoryMonths.finalPayment = false;
                                                    paymentHistoryMonths.status = 'Paid'
                                                }
                                                if (j > firstPaymentMonth) {
                                                    paymentHistoryMonths.finalPayment = false;
                                                    paymentHistoryMonths.status = 'Paid'
                                                }
                                            } else if (i === lastPaymentDate.getFullYear()) {
                                                if (j < lastPaymentDate.getMonth()) {
                                                    paymentHistoryMonths.finalPayment = false;
                                                    paymentHistoryMonths.status = 'Paid'
                                                }
                                                if (j === lastPaymentDate.getMonth()) {
                                                    paymentHistoryMonths.finalPayment = true;
                                                    paymentHistoryMonths.status = 'Paid'
                                                }
                                                if (j > lastPaymentDate.getMonth()) {
                                                    paymentHistoryMonths.finalPayment = false;
                                                    paymentHistoryMonths.status = null
                                                }
                                            }
                                            paymentHistory.months.push(paymentHistoryMonths)
                                        }
                                        if (this.paymentHistoryObject.paymentHistory.length !== 3) {
                                            this.paymentHistoryObject.paymentHistory.push(paymentHistory)
                                        }
                                    }
                                } else if (difference > 1) {
                                    console.log('2')
                                    for (let i = firstPaymentYear; i <= lastPaymentDate.getFullYear(); i++) {
                                        let paymentHistory: PaymentHistory = {
                                            year: '',
                                            months: []
                                        }
                                        console.log('First Loop:', i)
                                        paymentHistory.year = i.toString();
                                        console.log('testing')
                                        for (let j = 0; j < 12; j++) {
                                            let paymentHistoryMonths: PaymentHistoryMonths = {
                                                finalPayment: false,
                                                status: ''
                                            }
                                            console.log('Second loop:', j)
                                            if (i === firstPaymentYear) {
                                                if (j < firstPaymentMonth) {
                                                    paymentHistoryMonths.finalPayment = false;
                                                    paymentHistoryMonths.status = null
                                                }
                                                if (j === firstPaymentMonth) {
                                                    paymentHistoryMonths.finalPayment = false;
                                                    paymentHistoryMonths.status = 'Paid'
                                                }
                                                if (j > firstPaymentMonth) {
                                                    paymentHistoryMonths.finalPayment = false;
                                                    paymentHistoryMonths.status = 'Paid'
                                                }
                                            } else if (i === lastPaymentDate.getFullYear()) {
                                                if (j < lastPaymentDate.getMonth()) {
                                                    paymentHistoryMonths.finalPayment = false;
                                                    paymentHistoryMonths.status = 'Paid'
                                                }
                                                if (j === lastPaymentDate.getMonth()) {
                                                    paymentHistoryMonths.finalPayment = true;
                                                    paymentHistoryMonths.status = 'Paid'
                                                }
                                                if (j > lastPaymentDate.getMonth()) {
                                                    paymentHistoryMonths.finalPayment = false;
                                                    paymentHistoryMonths.status = null
                                                }
                                            } else {
                                                paymentHistoryMonths.finalPayment = false;
                                                paymentHistoryMonths.status = 'Paid'
                                            }
                                            paymentHistory.months.push(paymentHistoryMonths)
                                        }
                                        console.log('PaymentHistory:', paymentHistory)
                                        if (this.paymentHistoryObject.paymentHistory.length !== 4) {
                                            this.paymentHistoryObject.paymentHistory.push(paymentHistory)
                                        }
                                    }
                                }
                                console.log('PaymentHistoryObject:', this.paymentHistoryObject)
                                this.router.navigate(['/my-account']);
                                const reveredPaymentYears = this.paymentHistoryObject.paymentHistory.reverse()
                                this.paymentHistoryObject.paymentHistory = reveredPaymentYears
                                this.store.dispatch(loadPaymentHistory(this.paymentHistoryObject))
                                this.store.dispatch(hideInlineLoader())
                                return paylinkLoaded(paylink)
                            } else if (status === 'Past Due' || status === 'Pending Non-Pay Cancellation' || status === 'Cancelled Due to Non-Payment') {
                                console.log('One of the late payment options')
                                if (difference === 0) {
                                    console.log('0')
                                    for (let i = 0; i < 2; i++) {
                                        let paymentHistory: PaymentHistory = {
                                            year: '',
                                            months: []
                                        }
                                        paymentHistory.year = firstPaymentYear.toString()
                                        for (let j = 0; j < 12; j++) {
                                            let paymentHistoryMonths: PaymentHistoryMonths = {
                                                finalPayment: false,
                                                status: ''
                                            }
                                            if (i === 0) {
                                                if (j < firstPaymentMonth) {
                                                    paymentHistoryMonths.finalPayment = false;
                                                    paymentHistoryMonths.status = null
                                                }
                                                if (j === firstPaymentMonth) {
                                                    paymentHistoryMonths.finalPayment = false;
                                                    paymentHistoryMonths.status = 'Unpaid'
                                                }
                                                if (j > firstPaymentMonth && j < lastPaymentDate.getMonth()) {
                                                    paymentHistoryMonths.finalPayment = false;
                                                    paymentHistoryMonths.status = 'Unpaid'
                                                }
                                                if (j === lastPaymentDate.getMonth()) {
                                                    paymentHistoryMonths.finalPayment = true;
                                                    paymentHistoryMonths.status = 'Unpaid'
                                                }
                                                if (j > lastPaymentDate.getMonth()) {
                                                    paymentHistoryMonths.finalPayment = false;
                                                    paymentHistoryMonths.status = null
                                                }
                                            } else {
                                                paymentHistoryMonths.finalPayment = false;
                                                paymentHistoryMonths.status = null
                                            }
                                            paymentHistory.months.push(paymentHistoryMonths)
                                        }
                                        if (this.paymentHistoryObject.paymentHistory.length !== 2) {
                                            this.paymentHistoryObject.paymentHistory.push(paymentHistory)
                                        }
                                        firstPaymentYear++
                                    }
                                } else if (difference === 1) {
                                    console.log('1')
                                    for (let i = firstPaymentYear; i <= lastPaymentDate.getFullYear(); i++) {
                                        console.log(i, firstPaymentYear, lastPaymentDate.getFullYear())
                                        let paymentHistory: PaymentHistory = {
                                            year: '',
                                            months: []
                                        }
                                        paymentHistory.year = i.toString()
                                        for (let j = 0; j < 12; j++) {
                                            let paymentHistoryMonths: PaymentHistoryMonths = {
                                                finalPayment: false,
                                                status: ''
                                            }
                                            if (i === firstPaymentYear) {
                                                if (j < firstPaymentMonth) {
                                                    paymentHistoryMonths.finalPayment = false;
                                                    paymentHistoryMonths.status = null
                                                }
                                                if (j === firstPaymentMonth) {
                                                    paymentHistoryMonths.finalPayment = false;
                                                    paymentHistoryMonths.status = 'Unpaid'
                                                }
                                                if (j > firstPaymentMonth) {
                                                    paymentHistoryMonths.finalPayment = false;
                                                    paymentHistoryMonths.status = 'Unpaid'
                                                }
                                            } else if (i === lastPaymentDate.getFullYear()) {
                                                if (j < lastPaymentDate.getMonth()) {
                                                    paymentHistoryMonths.finalPayment = false;
                                                    paymentHistoryMonths.status = 'Unpaid'
                                                }
                                                if (j === lastPaymentDate.getMonth()) {
                                                    paymentHistoryMonths.finalPayment = true;
                                                    paymentHistoryMonths.status = 'Unpaid'
                                                }
                                                if (j > lastPaymentDate.getMonth()) {
                                                    paymentHistoryMonths.finalPayment = false;
                                                    paymentHistoryMonths.status = null
                                                }
                                            } else {
                                                paymentHistoryMonths.finalPayment = false;
                                                paymentHistoryMonths.status = 'Unpaid'
                                            }
                                            paymentHistory.months.push(paymentHistoryMonths)
                                        }
                                        console.log('PaymentHistory:', paymentHistory)
                                        if (this.paymentHistoryObject.paymentHistory.length !== 3) {
                                            this.paymentHistoryObject.paymentHistory.push(paymentHistory)
                                        }
                                    }
                                } else if (difference > 1) {
                                    console.log('2')
                                    for (let i = firstPaymentYear; i <= lastPaymentDate.getFullYear(); i++) {
                                        let paymentHistory: PaymentHistory = {
                                            year: '',
                                            months: []
                                        }
                                        paymentHistory.year = i.toString();
                                        console.log('testing')
                                        for (let j = 0; j < 12; j++) {
                                            let paymentHistoryMonths: PaymentHistoryMonths = {
                                                finalPayment: false,
                                                status: ''
                                            }
                                            if (i === firstPaymentYear) {
                                                if (j < firstPaymentMonth) {
                                                    paymentHistoryMonths.finalPayment = false;
                                                    paymentHistoryMonths.status = null
                                                }
                                                if (j === firstPaymentMonth) {
                                                    paymentHistoryMonths.finalPayment = false;
                                                    paymentHistoryMonths.status = 'Unpaid'
                                                }
                                                if (j > firstPaymentMonth) {
                                                    paymentHistoryMonths.finalPayment = false;
                                                    paymentHistoryMonths.status = 'Unpaid'
                                                }
                                            } else if (i === lastPaymentDate.getFullYear()) {
                                                if (j < lastPaymentDate.getMonth()) {
                                                    paymentHistoryMonths.finalPayment = false;
                                                    paymentHistoryMonths.status = 'Unpaid'
                                                }
                                                if (j === lastPaymentDate.getMonth()) {
                                                    paymentHistoryMonths.finalPayment = true;
                                                    paymentHistoryMonths.status = 'Unpaid'
                                                }
                                                if (j > lastPaymentDate.getMonth()) {
                                                    paymentHistoryMonths.finalPayment = false;
                                                    paymentHistoryMonths.status = null
                                                }
                                            } else {
                                                paymentHistoryMonths.finalPayment = false;
                                                paymentHistoryMonths.status = 'Unpaid'
                                            }
                                            paymentHistory.months.push(paymentHistoryMonths)
                                        }
                                        console.log('PaymentHistory:', paymentHistory)
                                        if (this.paymentHistoryObject.paymentHistory.length !== 4) {
                                            this.paymentHistoryObject.paymentHistory.push(paymentHistory)
                                        }
                                    }
                                }
                                console.log('End of loops')
                                let paymentsMade = paylink.data.paymentsMade
                                for (let i = 0; i < this.paymentHistoryObject.paymentHistory.length; i++) {
                                    for (let j = 0; j < 12; j++) {
                                        if (Number(paymentsMade) > 0) {
                                            console.log(Number(paymentsMade))
                                            if (this.paymentHistoryObject.paymentHistory[i].months[j].status !== null) {
                                                this.paymentHistoryObject.paymentHistory[i].months[j].status = 'Paid'
                                                paymentsMade = Number(paymentsMade) - 1
                                            }
                                        }
                                    }
                                }

                                let unpaidBoolean = false;
                                for (let i = 0; i < this.paymentHistoryObject.paymentHistory.length; i++) {
                                    console.log(this.paymentHistoryObject.paymentHistory.length)
                                    console.log('late 1')
                                    for (let j = 0; j < 12; j++) {
                                        console.log('i', i)
                                        if (this.paymentHistoryObject.paymentHistory[i].months[j].status === 'Late' && unpaidBoolean === false){
                                            unpaidBoolean = true;
                                            break;
                                        }
                                        if (this.paymentHistoryObject.paymentHistory[i].months[j].status === 'Unpaid' && unpaidBoolean === false) {
                                            console.log('late 1-1')
                                            this.paymentHistoryObject.paymentHistory[i].months[j].status = 'Late'
                                            unpaidBoolean = true;
                                        }
                                    }
                                }
                                const reveredPaymentYears = this.paymentHistoryObject.paymentHistory.reverse()
                                this.paymentHistoryObject.paymentHistory = reveredPaymentYears
                                console.log('PaymentHistoryObject:', this.paymentHistoryObject)
                                this.store.dispatch(loadPaymentHistory(this.paymentHistoryObject))
                                this.store.dispatch(hideInlineLoader())
                                this.router.navigate(['/my-account']);
                                return paylinkLoaded(paylink)
                            }

                            // else {
                            //     this.store.dispatch(hideInlineLoader())
                            //     // this.router.navigate(['/my-account']);
                            //     return noAccountFound()
                            // }
                        }
                        else if (paylink.data && paylink.data.installment ) {
                          console.log("SPPPPPPPPPPPPPPEFFECT", JSON.stringify(paylink, null, 2));
                          this.store.dispatch(paylinkLoaded(paylink))
                          this.paymentHistoryObject.paymentHistory.push(paylink.data.installment)
                          this.store.dispatch(loadSPPHistory(paylink))
                          const reveredPaymentYears = this.paymentHistoryObject.paymentHistory.reverse()
                          this.paymentHistoryObject.paymentHistory = reveredPaymentYears
                          this.store.dispatch(loadPaymentHistory(this.paymentHistoryObject))
                          this.store.dispatch(hideInlineLoader())
                          this.router.navigate(['/my-account']);
                          return paylinkLoaded(paylink)

                        }
                        else {

                        //   this.store.dispatch(hideInlineLoader())
                          this.router.navigate(['/my-account']);
                          return hideInlineLoader()
                      }
                    }),
                    catchError((error) => {
                        // this.store.dispatch(noAccountFound())
                        this.store.dispatch(hideInlineLoader())
                        this.router.navigate(['/my-account']);
                        return throwError(error)
                    }),
                )
            )
        )
    )



// public getSPPHistory$ = createEffect(() =>
// this.actions$.pipe(
//     ofType(loadSPPHistory),
//     switchMap((action: any) => this.accountService.get_SPP_Payment_History(action)
//         .pipe(
//             map(sppHistoryResponse => {
//                 console.log('ACCOUNTSPP', sppHistoryResponse);
//                 this.router.navigate(['/my-account']);
//                 this.store.dispatch(loadSPPHistorySuccess(sppHistoryResponse))
//                 this.store.dispatch(hideInlineLoader())
//                 return loadSPPHistorySuccess(sppHistoryResponse)

//             }),
//             catchError((error) => {
//                 this.store.dispatch(loadSPPHistoryFailure())
//                 this.store.dispatch(hideInlineLoader())
//                 return throwError(error)
//             }),
//         )
//     )
// )
//)

    constructor(
        private actions$: Actions,
        private accountService: AccountService,
        private store: Store<{}>,
        private router: Router
    ) {}
}


